import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "./ComplexTable";
import { columnsDataComplexForAdmin, columnsDataComplexForSeller } from "./columnsData";
import { useState, useEffect } from "react";
import { API_URL, get } from "utils/API";
import { getLocalToken } from "utils/token";
import { all } from "axios";
import { Button, Input, Modal } from "rsuite";
import Pagination from "react-js-pagination";
import { useSelector } from "react-redux";

export default function Users() {
  const user = useSelector(state => state.user)
  const [transactionDetails, setTransactionDetais] = useState([]);
  const [search, setSearch] = useState("")
  const [data, setData] = useState([]);
  const [open, setOpen] = useState(false);
  const [current, setCurrent] = useState(null);
  const [backdrop, setBackdrop] = useState("static");
  const [pagination, setPagination] = useState({
    search: "",
    active: 1,
    total_rows: 1
  })
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setCurrent(null);
    setOpen(false);
    setTransactionDetais([]);
  };

  const [currentPageData, setCurrentPageData] = useState(new Array(2).fill());
  // Example items, to simulate fetching from another resources.
  const viewTransactionDetails = async (transaction) => {
    var token = localStorage.getItem("hearhourshop");
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history/view/${transactionId}?token=${token}`)
    var response = await get(
      `${API_URL}/profile/transaction-history/view/${transaction?.id}`,
      token
    );
    processTransactionDetails(response.transaction_details);
    handleOpen();
    setCurrent(transaction);
  };

  const exportTransactionDetails = async (transactionId) => {
    var token = localStorage.getItem("hearhourshop");
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history/export/${transactionId}?token=${token}`)
    // await fetch(`https://mmoshop.hearhour.repl.co/profile/transaction-history/export/${transactionId}?token=${token}`)
    // var response = await get(`http://localhost:8000/profile/transaction-history/export/${transactionId}?token=${token}`)
    await fetch(
      `${API_URL}/profile/transaction-history/export/${transactionId}`,
      { headers: { Authorization: "Bearer " + getLocalToken() } }
    )
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = `${transactionId}.txt`; // Specify the filename
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        // Handle error
        console.error("Error:", error);
      });
  };

  const processTransactionDetails = (transactionDetails) => {
    var details = "";
    transactionDetails?.map((each) => {
      details += each.detail + "\n";
    });
    details = details.substring(0, details.length - 1);
    setTransactionDetais(details);
  };

  const processTransactionData = (allTransactions) => {
    var columnData = [];
    // console.log(allTransactions)
    allTransactions?.map((transaction) => {
      transaction["view"] = (
        <Button onClick={() => viewTransactionDetails(transaction)}>
          <img
            src="https://img.icons8.com/?size=512&id=CWkCzh9aKpqb&format=png"
            alt=""
            width={20}
            height={30}
          />
          View
        </Button>
      );
      transaction["export"] = (
        <Button onClick={() => exportTransactionDetails(transaction.id)}>
          <img
            src="https://img.icons8.com/?size=512&id=104149&format=png"
            alt=""
            width={20}
            height={30}
          />
          Export
        </Button>
      );
      columnData.push(transaction);
    });
    return columnData;
  };

  const transactionsOnSearchKey = async () => {
    getTransactions(1)
  }

  const getTransactions = async (page) => {
    var token = getLocalToken();
    var response = await get(`${API_URL}/admin/get-transactions?page=${!page ? pagination.active : page}${search === "" ? "": "&search=" + search}`, token);
    // console.log(response.code)
    if (response?.code !== 200) {
      return;
    }
    setPagination({...pagination, total_rows: response?.total_rows, active: response?.page})
    setData(processTransactionData(response?.data));
  };

  useEffect(() => {
    getTransactions()
  }, [pagination.active])

  useEffect(() => {
    getTransactions();
  }, []);

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb="20px"
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}
      >
        <Input placeholder="Transaction ID/Product Name/Username" value={search} onChange={(e) => setSearch(e)} onKeyDown={(e) => {
          if (e.key === "Enter") {transactionsOnSearchKey()}
        }}/>
        <ComplexTable columnsData={user?.metadata?.role === "ADMIN" ? columnsDataComplexForAdmin : columnsDataComplexForSeller} tableData={data} />
        <div className="pagination p-5">
          <Pagination
            activePage={pagination.active}
            itemsCountPerPage={20}
            totalItemsCount={pagination.total_rows}
            pageRangeDisplayed={10}
            onChange={(e) => setPagination({ ...pagination, active: e })}
          />
        </div>

        {/* <SweetPagination
        currentPageData={setCurrentPageData}
        getData={items}
        navigation={true}
        dataPerPage={10}
        getStyle={'pagination-style'}
      /> */}

        {open && (
          <div className="" style={{ zIndex: 999 }}>
            <Modal
              backdrop={backdrop}
              keyboard={false}
              open={open}
              onClose={handleClose}
              size="md"
            >
              <Modal.Header>
                <h5>Transaction Details</h5>
              </Modal.Header>

              <Modal.Body>
                <Input
                  as="textarea"
                  rows={30}
                  placeholder="Accounts"
                  value={transactionDetails && transactionDetails}
                  onChange={(e) => setTransactionDetais(e)}
                />
              </Modal.Body>
              <Modal.Footer>
                <div className="grid grid-cols-2 items-center justify-center">
                  <Button
                    className="button-buy"
                    onClick={() => exportTransactionDetails(current?.id)}
                    style={{ color: "black" }}
                  >
                    Download .txt File
                  </Button>
                  <Button className="button-buy" onClick={handleClose}>
                    Cancel
                  </Button>
                </div>
              </Modal.Footer>
            </Modal>
          </div>
        )}

        {/* <PatternTable columnDef={columnsDataComplex} columnData={data} /> */}
      </SimpleGrid>
    </Box>
  );
}
