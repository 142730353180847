// chakra imports
import { Box, Flex, Stack } from "@chakra-ui/react";
//   Custom components
import Brand from "./Brand"; //components/sidebar/components/Brand
import Links from "./Links";
import SidebarCard from "./SidebarCard";
import React, { useState, useEffect } from "react";

// FUNCTIONS

function SidebarContent(props) {
  const { routes, setView, view, isOpen} = props;
  const [width, setWidth] = useState(1080)
  // SIDEBAR
  useEffect(() => {
    var width = document.body.width
    if (width) {
      setWidth(width)
    }

  }, [])
  return (
    <Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
      <Brand />
      <Stack direction='column' mb='auto' mt='8px'>
        <Box ps='20px' pe={{ md: "16px", "2xl": "1px" }}>
          {

          }
          <Links routes={routes} setView={setView} view={view}/>
        </Box>
      </Stack>
{/* 
      <Box
        ps='20px'
        pe={{ md: "16px", "2xl": "0px" }}
        mt='60px'
        mb='40px'
        borderRadius='30px'>
        <SidebarCard />
      </Box> */}
    </Flex>
  );
}

export default SidebarContent;
