export const columnsDataComplexForSeller = [
  {
    Header: "#",
    accessor: "#",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Product",
    accessor: "product_name"
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "TOTAL",
    accessor: "total",
  },
  {
    Header: "DATE",
    accessor: "date", 
  },
  {
    Header: "VIEW",
    accessor: "view",
  },
  {
    Header: "EXPORT",
    accessor: "export",
  },
];
export const columnsDataComplexForAdmin = [
  {
    Header: "#",
    accessor: "#",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Product",
    accessor: "product_name"
  },
  {
    Header: "Seller",
    accessor: "seller"
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "TOTAL",
    accessor: "total",
  },
  {
    Header: "DATE",
    accessor: "date", 
  },
  {
    Header: "VIEW",
    accessor: "view",
  },
  {
    Header: "EXPORT",
    accessor: "export",
  },
];

// export const columnsDataComplex = [
//   {
//     header: "#",
//     accessor: "#",
//   },
//   {
//     header: "ID",
//     accessor: "id",
//   },
//   {
//     header: "USERNAME",
//     accessor: "username",
//   },
//   {
//     header: "TOTAL",
//     accessor: "total",
//   },
//   {
//     header: "DATE",
//     accessor: "date",
//   },
//   {
//     header: "View",
//     accessor: "view",
//   },
//   {
//     header: "Export",
//     accessor: "export",
//   },
// ];
