import { useEffect, useState, useCallback } from "react";
import { Button, Input, InputGroup } from "rsuite";
import { API_URL, get } from "utils/API";
import { getLocalToken } from "utils/token";
import {
  Card,
  CardBody,
  Avatar,
  CardHeader,
  IconButton,
  Text,
  Image,
  CardFooter,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";
import Country from "components/Country/Country";
import Select from "react-select"
import { countries } from "components/Country/Country";
import axios from "axios";
import Error404 from "./error.png"

const ProfileModal = ({ open, handleCloseModal, user, users, setUsers, fetchProducts }) => {

    const options = countries.map(country => {
      return {label: <div className="flex"><Country country={country}/> {country}</div>, value: country}
    })

    const changeProductInfo = (productId, property, newValue) => {
        setUsers(users.map(user => {
          if (user.id === productId) {
            return {...user, [property]: newValue}
          } else {
            return user
          }
        }))
    }

    const deleteProduct = async () => {
      var confirmation = window.prompt(`Enter ${user.id} to delete`)
      if (parseInt(confirmation) !== parseInt(user.id)) {
            /* Toast here */
            return
      }
      var response = await axios.delete(`${API_URL}/admin/delete-products`, {
        data: user,
        headers: { "Authorization": "Bearer " + getLocalToken() }
      }).catch(() => {
        /* Toast here */
      });
    
      fetchProducts();
    };


    const updateProduct = async () => {
      var response = await axios.put(`${API_URL}/admin/edit-products`, user, {headers: 
        {"Authorization": `Bearer ` + getLocalToken()}
      }).catch(() => {
        /* Toast here */
      })
      if (response?.status != 200) {
        /* Toast here */
      }
      fetchProducts()
    }

  
  // const renderIcon = (icon_url) => {
  //   var ERROR = <Avatar name={user?.name} src={Error404}/>
  //   try {
  //     <img src={icon_url} alt=""/>
  //     return <Avatar name={user?.name} src={user?.icon} />
  //   } catch {
  //     return <Avatar name={user?.name} src={Error404}/>
  //   }

    // return <Avatar name={user?.name} src={Error404}/>
    // var image = new Image();
    // image.src = icon_url
    // image.onload = async () => {
    //   return <Avatar name={user?.name} src={user?.icon} />
    // }
    // image.onerror = async () => {
    //   return <Avatar name={user?.name} src={Error404} />
    // }
  // }

  return (
    <Modal isOpen={open} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Edit Product</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
        <div className="w-full flex justify-center items-center">
          <Avatar name={user?.name} src={user.icon}/>
        </div>
        
          <p>
            <strong>ID:</strong> <Input value={user?.id} type="text" disabled/>
          </p>
          <p>
            <strong>Name:</strong> <Input value={user?.name} onChange={(value) => changeProductInfo(user.id, "name", value)} type="text" />
          </p>
          <p>
            <strong>Category:</strong> <Input value={user?.category} onChange={(value) => changeProductInfo(user.id, "category", value)} type="text" />
          </p>
          <p>
            <strong>Icon URL:</strong> <Input value={user?.icon} onChange={(value) => changeProductInfo(user.id, "icon", value)}/>
          </p>
          <p>
            <strong>Detail:</strong> <Input value={user?.detail} onChange={(value) => changeProductInfo(user.id, "detail", value)} type="text" />
          </p>
          <p>
            <strong>Owner:</strong> <Input value={user?.owner} onChange={(value) => changeProductInfo(user.id, "owner", value)} type="number" />
          </p>
          <p>
            <strong>Country:</strong><Select options={options} onChange={(e) => changeProductInfo(user.id, "country", e.value)} className="z-50"/>
          </p>
          <p>
            <strong>Price:</strong>  <InputGroup inside style={{width: "100%", marginBottom: 10}}>
            <InputGroup.Addon>$</InputGroup.Addon>
            <Input value={user?.price} onChange={(value) => changeProductInfo(user.id, "price", parseFloat(parseFloat(value).toFixed(3)))} type="number" />
       
          </InputGroup>
          </p>
          {/* Add your form fields or content here */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => updateProduct()} appearance="primary" color="green">
            Save
          </Button>
          <Button colorScheme="blue" mr={3} onClick={() => deleteProduct()} appearance="primary" color="red">
            Permanently Delete
          </Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function EditProduct() {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const { onClose } = useDisclosure();

  const fetchProducts = useCallback(async () => {
    const response = await get(
      `${API_URL}/admin/get-products?search=${search}`,
      getLocalToken()
    );
    if (response.code !== 200) {
      return;
    }
    setUsers(response.products);
    setIsOpen(Array(response.products.length).fill(false)); // Initialize isOpen array
  }, [search]);

  const updateBalance = (e, user) => {
    setUsers((prevUsers) =>
      prevUsers.map((eachUser) => {
        if (eachUser.id === user.id) {
          eachUser.balance = e;
        }
        return eachUser;
      })
    );
  };

  useEffect(() => {
    fetchProducts();
  }, [search, fetchProducts]);

  const handleOpenModal = (index) => {
    setIsOpen((prevIsOpen) =>
      prevIsOpen.map((value, i) => (i === index ? true : value))
    );
  };

  const handleCloseModal = (index) => {
    setIsOpen((prevIsOpen) =>
      prevIsOpen.map((value, i) => (i === index ? false : value))
    );
    onClose();
  };

  return (
    <div className="pt-20 space-y-4">
      <div className="grid grid-cols-10">
        <div className="col-span-5 lg:col-span-4 xl:col-span-3">
          <p className="font-bold">
            Search for products (ID, Name, Category, Price, or Owner's username):
          </p>
        </div>
        <div className="col-span-5 lg:col-span-6 xl:col-span-5">
          <Input onChange={(e) => setSearch(e)} value={search} />
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 ">
          {users?.map((user, index) => (
            <div className="col-span-1 w-full" key={user.id}>
              <Card maxW="md" className="h-full">
                <CardHeader>
                  <div className="w-full text-center">
                    <Avatar name={user?.username} src={user?.icon} />
                  </div>
               
                  <IconButton
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="See menu"
                    icon={null}
                  />
                </CardHeader>
                <CardBody>
                  <p>
                    <strong>ID:</strong> {user?.id}
                  </p>
                  <p>
                    <strong>Name:</strong> {user?.name}
                  </p>
                  <p>
                    <strong>Category:</strong> {user?.category}
                  </p>
                </CardBody>
                <CardFooter
                  justify="space-between"
                  flexWrap="wrap"
                  sx={{
                    "& > button": {
                      minW: "136px",
                    },
                  }}
                >
                  <div style={{ width: "100%" }}>
                    {/* Add any additional content here */}
                  </div>
                  <Button
                    onClick={() => handleOpenModal(index)}
                    className="button-buy"
                  >
                    Edit
                  </Button>
                </CardFooter>
              </Card>
              <ProfileModal
                open={isOpen[index]}
                handleCloseModal={() => handleCloseModal(index)}
                user={user}
                users={users}
                setUsers={setUsers}
                fetchProducts={fetchProducts}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
