/*!
  _   _  ___  ____  ___ ________  _   _   _   _ ___   
 | | | |/ _ \|  _ \|_ _|__  / _ \| \ | | | | | |_ _| 
 | |_| | | | | |_) || |  / / | | |  \| | | | | || | 
 |  _  | |_| |  _ < | | / /| |_| | |\  | | |_| || |
 |_| |_|\___/|_| \_\___/____\___/|_| \_|  \___/|___|
                                                                                                                                                                                                                                                                                                                                       
=========================================================
* Horizon UI - v1.1.0
=========================================================

* Product Page: https://www.horizon-ui.com/
* Copyright 2022 Horizon UI (https://www.horizon-ui.com/)

* Designed and Coded by Simmmple

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Avatar,
  Box,
  Flex,
  FormLabel,
  Icon,
  Select,
  SimpleGrid,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import Usa from "assets/img/dashboards/usa.png";
import axios from "axios";
// Custom components
import MiniCalendar from "components/calendar/MiniCalendar";
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import React, { useState } from "react";
import {
  MdAddTask,
  MdAttachMoney,
  MdBarChart,
  MdFileCopy,
} from "react-icons/md";
import { getLocalToken } from "utils/token";
import CheckTable from "views/admin/default/components/CheckTable";
import ComplexTable from "views/admin/default/components/ComplexTable";
import DailyTraffic from "views/admin/default/components/DailyTraffic";
import PieCard from "views/admin/default/components/PieCard";
import Tasks from "views/admin/default/components/Tasks";
import TotalSpent from "views/admin/default/components/TotalSpent";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import {
  columnsDataCheck,
  columnsDataComplex,
} from "views/admin/default/variables/columnsData";
import tableDataCheck from "views/admin/default/variables/tableDataCheck.json";
import tableDataComplex from "views/admin/default/variables/tableDataComplex.json";
import { useEffect } from "react";
import { API_URL, get } from "utils/API";
import { useSelector } from "react-redux";
import Online from "images/online.png"
import PatternTable from "./Table";
import Loading from "images/loading.svg"
import { useNavigate } from "react-router";

const adminSalesColumnDef = [
  {
    header: "Month",
    accessor: "month",
  }, 
  {
    header: "Main Sales",
    accessor: "total_sum",
    prefix: "$",
    className: "text-center"
  },
  {
    header: "Other Sales",
    accessor: "total_sum_others",
    prefix: "$",
    className: "text-center"
  },
  {
    header: "Combined",
    accessor: "total_sum_combined",
    prefix: "$",
    className: "text-center"
  }
]

const sellerSalesColumnDef = [
  {
    header: "Month",
    accessor: "month",
  }, 
  {
    header: "Main Sales",
    accessor: "total_sum",
    prefix: "$",
    className: "text-center"
  },
]


export default function UserReports() {
  const [stats, setStats] = useState([])
  const user = useSelector(state => state.user)
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const navigate = useNavigate()
  useEffect(() => {
    // if (!["ADMIN", "SELLER"].includes(user?.metadata?.role)) {
    //   navigate("/")
    //   return
    // }
    const fetchStats = async () => {
      let response = await get(`${API_URL}/admin/get-statistics`, getLocalToken())
      if (response?.code !== 200) {
        return
      }
      delete response.code
      setStats(response)
    }
    fetchStats()
  }, [])

  // useEffect(() => console.log(stats?.sales_by_month))
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 6 }}
        gap='20px'
        mb='20px'>
        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Total Sales'
          value={stats.earnings >= 0.0 ? stats?.earnings.toFixed(2) : <img src={Loading} alt="loading" width={40} height={40}/>}
        />

        <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
              }
            />
          }
          name='Balance'
          value={user?.metadata?.balance >= 0.0 ? user?.metadata?.balance.toFixed(2) : <img src={Loading} alt="loading" width={40} height={40}/>}
        />

     
        {/* <MiniStatistics growth='+23%' name='Sales' value='$574.34' /> */}
        {/* <MiniStatistics
          endContent={
            <Flex me='-16px' mt='10px'>
              <FormLabel htmlFor='balance'>
                <Avatar src={Usa} />
              </FormLabel>
              <Select
                id='balance'
                variant='mini'
                mt='5px'
                me='0px'
                defaultValue='usd'>
                <option value='usd'>USD</option>
                <option value='eur'>EUR</option>
                <option value='gba'>GBA</option>
              </Select>
            </Flex>
          }
          name='Your balance'
          value='$1,000'
        /> */}
        {user?.metadata?.role === "ADMIN" && 

        <div className="flex">
          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg={boxBg}
                icon={
                  <Icon w='32px' h='32px' as={MdAttachMoney} color={brandColor} />
                }
              />
            }
            name='Hotmail Balance'
            value={stats?.hotmail_balance ? stats.hotmail_balance.toFixed(2) : <img src={Loading} alt="loading" width={40} height={40}/>}
          />


          <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                bg='linear-gradient(90deg, #4481EB 0%, #04BEFE 100%)'
                icon={<img src={Online} alt="Online"/>}
              />
            }
            name='Currently online'
            value={stats?.currently_online}
          />
            <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                icon={null}
              />
            }
            name='Total users'
            value={stats?.users?.total_count}
          />
            <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                icon={null}
              />
            }
            name='Sellers'
            value={stats?.users?.seller_count}
          />
            <MiniStatistics
            startContent={
              <IconBox
                w='56px'
                h='56px'
                icon={null}
              />
            }
            name='Users'
            value={stats?.users?.user_count}
          />
        </div>
        }
    
        {/* <MiniStatistics
          startContent={
            <IconBox
              w='56px'
              h='56px'
              bg={boxBg}
              icon={
                <Icon w='32px' h='32px' as={MdFileCopy} color={brandColor} />
              }
            />
          }
          name='Total Projects'
          value='2935'
        /> */}
      </SimpleGrid>

      {/* <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px' mb='20px'>
        <TotalSpent />
        <WeeklyRevenue />
      </SimpleGrid> */}
      {/* {stats?.sales_by_month && stats?.sales_by_month.map(each => {
        return <p>{each.month} ${each.total_sum}</p>
      })} */}
      <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
      <PatternTable columnDef={user?.metadata?.role === "ADMIN" ? adminSalesColumnDef : sellerSalesColumnDef} columnData={stats?.sales_by_month && stats?.sales_by_month}/>
        {/* {stats?.sales_by_month &&  <CheckTable columnsData={columnsDataCheck} tableData={[{month: "222", total_sum: 2},]} />} */}
{/*        stats?.sales_by_month
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <DailyTraffic />
          <PieCard />
        </SimpleGrid> */}
      </SimpleGrid>
      {/* <SimpleGrid columns={{ base: 1, md: 1, xl: 2 }} gap='20px' mb='20px'>
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={tableDataComplex}
        />
        <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap='20px'>
          <Tasks />
          <MiniCalendar h='100%' minW='100%' selectRange={false} />
        </SimpleGrid>
      </SimpleGrid> */}
    </Box>
  );
}
