import Country from "components/Country/Country"
import PropTypes from 'prop-types';

export default function Label({name, country}) {
    
    return (
        <div className="flex">
            <p>{name}</p>
            <Country country={country}/>
        </div>
    )
}

Label.defaultProps = {
    name: "",
    country: "CAMBODIA"
}

Label.propTypes = {
    name: PropTypes.string,
    country: PropTypes.string
}