export const columnsDataCheck = [
  {
    Header: "Month",
    accessor: "month",
  },
  {
    Header: "Sales",
    accessor: "total_sum",
  },

];

  // {
  //   Header: "QUANTITY",
  //   accessor: "quantity",
  // },
  // {
  //   Header: "DATE",
  //   accessor: "date",
  // },
export const columnsDataComplex = [
  {
    Header: "NAME",
    accessor: "name",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
