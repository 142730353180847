/* eslint-disable */
import React, { useEffect, useState } from "react";
import { NavLink, useLocation, Link, useNavigate } from "react-router-dom";
// chakra imports
import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import AdminMain from "pages/AdminDashboard/AdminMain";
import Back from "components/sidebar/icons/back.png"
import { useSelector } from "react-redux";

export function SidebarLinks(props) {
  const navigate = useNavigate()
  const [current, setCurrent] = useState("Main")
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  const user = useSelector(state => state.user)
  const { routes, view, setView } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };

  const updateDashboardFeatureView = (route) => {
    setCurrent(route.name)


    setView(route.component)

    localStorage.setItem("redirector", route.name)
  }

  useEffect(() => {
    var redirector = localStorage.getItem("redirector")
    setCurrent(redirector)
    routes?.forEach(route => {
      if (route.name === redirector) {
        setView(route.component)
        return
      }
    })
  }, [])


  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  // const createLinks = (routes) => {
  //   return routes.map((route, index) => {
  //     if (route.category) {
  //       return (
  //         <>
  //           <Text
  //             fontSize={"md"}
  //             color={activeColor}
  //             fontWeight='bold'
  //             mx='auto'
  //             ps={{
  //               sm: "10px",
  //               xl: "16px",
  //             }}
  //             pt='18px'
  //             pb='12px'
  //             key={index}>
  //             {route.name}
  //           </Text>
  //           {createLinks(route.items)}
  //         </>
  //       );
  //     } else if (
  //       route.layout === "/admin" ||
  //       route.layout === "/auth" ||
  //       route.layout === "/rtl"
  //     ) {
  //       return (
  //         <NavLink key={index} to={route.layout + route.path}>
  //           {route.icon ? (
  //             <Box>
  //               <HStack
  //                 spacing={
  //                   activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
  //                 }
  //                 py='5px'
  //                 ps='10px'>
  //                 <Flex w='100%' alignItems='center' justifyContent='center'>
  //                   <Box
  //                     color={
  //                       activeRoute(route.path.toLowerCase())
  //                         ? activeIcon
  //                         : textColor
  //                     }
  //                     me='18px'>
  //                     {route.icon}
  //                   </Box>
  //                   <Text
  //                     me='auto'
  //                     color={
  //                       activeRoute(route.path.toLowerCase())
  //                         ? activeColor
  //                         : textColor
  //                     }
  //                     fontWeight={
  //                       activeRoute(route.path.toLowerCase())
  //                         ? "bold"
  //                         : "normal"
  //                     }>
  //                     {route.name}
  //                   </Text>
  //                 </Flex>
  //                 <Box
  //                   h='36px'
  //                   w='4px'
  //                   bg={
  //                     activeRoute(route.path.toLowerCase())
  //                       ? brandColor
  //                       : "transparent"
  //                   }
  //                   borderRadius='5px'
  //                 />
  //               </HStack>
  //             </Box>
  //           ) : (
  //             <Box>
  //               <HStack
  //                 spacing={
  //                   activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
  //                 }
  //                 py='5px'
  //                 ps='10px'>
  //                 <Text
  //                   me='auto'
  //                   color={
  //                     activeRoute(route.path.toLowerCase())
  //                       ? activeColor
  //                       : inactiveColor
  //                   }
  //                   fontWeight={
  //                     activeRoute(route.path.toLowerCase()) ? "bold" : "normal"
  //                   }>
  //                   {route.name}
  //                 </Text>
  //                 <Box h='36px' w='4px' bg='brand.400' borderRadius='5px' />
  //               </HStack>
  //             </Box>
  //           )}
  //         </NavLink>
  //       );
  //     }
  //   });
  // };
  //  BRAND
  return (
        <div className="p-2">
  
          {routes.map((route, index) => {
            if (route.role.includes(user.metadata?.role)) {
              return (<div className={`flex hover:bg-blue-200 rounded-lg  active:bg-white cursor-pointer ${current === route.name ? "bg-blue-200" :  ""}`} key={index}>
                  <div  onClick={() => updateDashboardFeatureView(route)} className={`w-full pe-2 font-bold font-lg m-2 flex space-x-3`}>
                    <span>{route.icon}</span>
                    <span>{route.name}</span>
                  </div>
            </div>)
            }
     
          })}
          <div  onClick={() => navigate("/")} className={`w-full pe-2 font-bold font-lg m-2 flex space-x-3 hover:bg-blue-200 rounded-lg active:bg-white cursor-pointer items-center`} style={{position: "absolute", bottom:0, left:0}}>
                    <span><img src={Back} width={30} height={30}/></span>
                    <span>Back</span>
                  </div>
          </div>);
}

export default SidebarLinks;