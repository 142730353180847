


export const columnsDataComplex = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "AMOUNT",
    accessor: "amount",
  },
  {
    Header: "TRANSACTION_ID",
    accessor: "transaction_id",
  },
  {
    Header: "STATUS",
    accessor: "status",
  },
  {
    Header: "DATE",
    accessor: "date",
  },
  {
    Header: "PROGRESS",
    accessor: "progress",
  },
];
