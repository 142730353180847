import { Button, Input } from "rsuite";
import { useEffect, useState, useCallback } from "react";
import { get, post, API_URL } from "utils/API";
import { getLocalToken } from "utils/token";
import axios from "axios";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast";
import { useFetcher } from "react-router-dom";
import { COUNTRY_OPTIONS } from "components/Country/Country";
const UserOption = ({user}) => {

    return (
        <div className="flex">
            <img src={user?.picture} alt={user?.username} width={30} height={30} className="rounded-full"/>
            <p>{user?.username} <strong>({user?.role})</strong></p>
        </div>
    )
}

const AddProduct = () => {
    const [productInfo, setProductInfo] = useState({
        name: "",
        category: "",
        country: "",
        detail: "",
        price: 0.0,
        icon_url: "",
        assignee: null,
    })
    const [users, setUsers] = useState([])
    const [search, setSearch] = useState("")
    const submitProduct = async () => {
        var response = await post(`${API_URL}/admin/add-product`, {
            token: getLocalToken(),
            body:  {...productInfo, price: parseFloat(parseFloat(productInfo.price).toFixed(3))}
        }
       )
    
        if (response.code !== 200) {
            toast.error(response?.message, TOAST_OPTIONS)
            return
        }
        setProductInfo({
            name: "",
            category: "",
            country: "",
            detail: "",
            price: 0.0,
            icon_url: "",
        })
        toast.success(response?.message, TOAST_OPTIONS)
    }

    const processUsersForSelection = (userInput) => {
        var selections = []
        users?.values.map((user) => {
            if (user.username.includes(userInput.value)) {
                selections.push({label: <UserOption user={user}/>, value: user.username}) 
                setProductInfo({...productInfo, assignee: user.id})
            }
        })
        return selections
    }

    const parsePrice = (amount) => {
        // const parsedAmount = parseFloat(amount);
        
        // if (isNaN(parsedAmount) || amount.trim() === "" || parsedAmount < 0) {
        //   setProductInfo({...productInfo, price: 0.0});
        //   return;
        // }
        
        setProductInfo({...productInfo, price: amount});
      }

    useEffect(() => {
        const fetchUsers = async () => {
            var response = await get(`${API_URL}/admin/get-users?search=${search}`, getLocalToken())
            if (response.status !== 200) {
                /* Toast here */
            }
           var users = response?.data
           if (!users) {return}
           var userOptions = users?.filter(user => user.role === "ADMIN" || user.role === "SELLER")?.map(user => ({
            label: <UserOption user={user}/>,
            value: user?.username
          }));
           setUsers({
            values: users,
            options: userOptions
        })
        }
        fetchUsers()
    }, [])

    return (
        <div className="flex flex-col pt-20 space-y-4">
            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
            />

            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Product Name: </p></div>
                <div className="basis-8/12 md:basis-4/12"><Input onChange={e => setProductInfo({...productInfo, name: e})} value={productInfo.name} maxLength={25}/></div>
            </div>
            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Product Category: </p></div>
                <div className="basis-8/12 md:basis-4/12"><Input onChange={e => setProductInfo({...productInfo, category: e})} value={productInfo.category}/></div>
            </div>
            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Product Country: </p></div>
                <div className="basis-8/12 md:basis-4/12"><Select options={COUNTRY_OPTIONS} onChange={(e) => setProductInfo({...productInfo, county: e.value})}/></div>
            </div>
            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Product Detail: </p></div>
                <div className="basis-8/12 md:basis-4/12"><Input onChange={e => setProductInfo({...productInfo, detail: e})} value={productInfo.detail}/></div>
            </div>
            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Product Price: </p></div>
                <div className="basis-8/12 md:basis-4/12"><Input onChange={e => parsePrice(e)} value={productInfo.price}/></div>
            </div>
            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Product Icon URL (if exists): </p></div>
                <div className="basis-8/12 md:basis-4/12"><Input onChange={e => setProductInfo({...productInfo, icon_url: e})} value={productInfo.icon_url} type="text"/></div>
            </div>
            <div className="flex flex-row">
                <div className="basis-4/12 md:basis-3/12 lg:basis-2/12 font-bold"><p>Assign to: </p></div>
                <div className="basis-8/12 md:basis-4/12"><Select options={users.options && users.options} onChange={(e) => processUsersForSelection(e)}/></div>
            </div>

            <div className="flex flex-row ">
                <div className="basis-full  md:basis-7/12 lg:basis-6/12  items-center justify-center flex">
                    <Button appearance="primary" color="blue" onClick={() => submitProduct()}>Submit</Button>
                </div>
            </div>   
        </div>
    )
}

export default AddProduct;