import { useState, useEffect, useCallback } from "react";
import {get, API_URL, post} from "utils/API.js"
import { Input, Button} from 'rsuite';
import Upload from "views/admin/profile/components/Upload";
import Country from "components/Country/Country";

import Select from 'react-select';
import Label from "./Label";
import { getLocalToken } from "utils/token";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast";

const aquaticCreatures = [
  { label: <Country/>, value: 'Shark' },
  { label: 'Dolphin', value: 'Dolphin' },
  { label: 'Whale', value: 'Whale' },
  { label: 'Octopus', value: 'Octopus' },
  { label: 'Crab', value: 'Crab' },
  { label: 'Lobster', value: 'Lobster' },
];

const AddAccount = () => {
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProducts] = useState(null)
    const [text, setText] = useState("")
    const [file, setFile] = useState(null)
    const [content, setContent] = useState(null)

    const processProductsForSelection = useCallback((products) => {
        var selections = []
        products?.map((each) => {
            selections.push({label: <Label name={each.name} country={each.country}/>, value: each}) //<Label name={each.name} country={each.country}/>
        })
        return selections
    }, [])

    const updateSelectedProduct = useCallback((e)  => {setSelectedProducts(e)}, [])

    const fetchProducts = async () => {
        try {
          // var response = await get('http://localhost:8000/admin/get-products', getLocalToken());
          var response = await get(`${API_URL}/admin/get-products?search=`, getLocalToken())
          setProducts(processProductsForSelection(response.products));
        } catch (error) {
          console.log('Error fetching data:', error);
        }
      };
    
      const addAccounts = (isFile) => {
        if (selectedProduct === null) {return}

        var token = localStorage.getItem("hearhourshop")
        if (token === null) {
          /**Toast here */
          return
        }

        if (isFile && file !== null) {
          var formData = new FormData()
          formData.append("product_id", selectedProduct?.value?.id)
          formData.append("file", file[0])
          formData.append("token", token)
          axios.post(`${API_URL}/admin/add-accounts?isFile=${isFile}&product_id=${selectedProduct?.value?.id}`, 
            formData
          ,  {
            headers: {
              "Authorization": `Bearer ${token}`,
              "Content-Type": "multipart/form-data"
            }
          })
            .then((res) => res.json())
            .then((data) => console.log(data))
            .catch((err) => toast.error("Error adding your accounts", TOAST_OPTIONS));
            return
        };
     
        
        var response = post(`${API_URL}/admin/add-accounts?isFile=${isFile}`, {
          token: getLocalToken(),
          body: {
            text: text, 
            product_id: selectedProduct?.value?.id,
            token: token
          }
        })
        toast.success("Your accounts are being added in the background. You can leave this page anytime.", TOAST_OPTIONS)
      }

      useEffect(() => {
        addAccounts(true)
      }, [file])

      useEffect(() => {
      }, [selectedProduct])


    useEffect(() => {
      fetchProducts();
    }, []);


    return (
        <div className="pt-20 space-y-4">
            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
              />


            <div className="flex">
                <div className="flex-1">
                        <Select options={products} onChange={(e) => updateSelectedProduct(e)}/>
                </div>
            </div>
            <div className="grid grid-cols-8">
                <div className="col-span-4 space-y-4">
                    <Input as="textarea" rows={30} placeholder={selectedProduct ? selectedProduct.value.detail : "Account details here"} value={text} onChange={(e) => setText(e)}/>
                    <div className="flex justify-center">
                         <Button appearance="primary" color="blue" onClick={() => addAccounts(false)}>Add</Button>
                    </div>
                 
                </div>
                <div className="col-span-4 flex justify-center">
                        <Upload
                setFile={setFile}
                content={content}
                gridArea={{
                    base: "3 / 1 / 4 / 2",
                    lg: "1 / 3 / 2 / 4",
                }}
                minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
                pe='20px'
                pb={{ base: "100px", lg: "20px" }}
                />
        </div>
                </div>
            </div>
           
          
    )
}

export default AddAccount;