import React from "react";

import { Icon } from "@chakra-ui/react";
import {
  MdBarChart,
  MdPerson,
  MdHome,
  MdLock,
  MdOutlineShoppingCart,
} from "react-icons/md";
import Home from "components/sidebar/icons/home.png"
import Invoice from "components/sidebar/icons/invoices.png"
import Order from "components/sidebar/icons/orders.png"
import Product from "components/sidebar/icons/products.png"
import Member from "components/sidebar/icons/members.png"
import Transaction from "components/sidebar/icons/transactions.png"
import AddProducts from "components/sidebar/icons/add-products.png"
import AddAccounts from "components/sidebar/icons/add-accounts.png"
import AddBalances from "components/sidebar/icons/add-balance.png"
import EditProducts from "components/sidebar/icons/edit-product.png"
import WorkerIcon from "components/sidebar/icons/worker.png"
import RequestPayoutIcon from "components/sidebar/icons/request-payout.png"
import AnnouncementIcon from "components/sidebar/icons/announcement.png"
import PaymentMethod from "components/sidebar/icons/payment-method.png"
import RequestProductIcon from "components/sidebar/icons/request-product.png"

// Admin Imports
import MainDashboard from "./views/admin/default";
import NFTMarketplace from "./views/admin/marketplace";
import Profile from "./views/admin/profile";
import DataTables from "./views/admin/dataTables";

// import Invoices from "./views/admin/invoices";
import Invoices from "./pages/AdminDashboard/Invoices/index.jsx";
import Members from "./pages/AdminDashboard/Members/index.jsx";
import Transactions from "./pages/AdminDashboard/Transactions/index.jsx";
import RTL from "./views/admin/rtl";
import AddAccount from "pages/AdminDashboard/AddAccount/AddAccount";
import AddProduct from "pages/AdminDashboard/AddProduct/AddProduct"
import AdminMain from "pages/AdminDashboard/AdminMain";
import AddBalance from "pages/AdminDashboard/AddBalance/AddBalance"
import EditProduct from "pages/AdminDashboard/EditProduct/EditProduct";
import Worker from "pages/AdminDashboard/Worker";
import Announcement from "pages/AdminDashboard/Announcement";
import RequestPayout from "pages/AdminDashboard/Payout/RequestPayout";

// Auth Imports
import SignInCentered from "./views/auth/signIn";
import AddPaymentMethod from "pages/AdminDashboard/Payout/AddPayment";
import PayoutHistory from "pages/AdminDashboard/Payout/PayoutHistory";
import ApprovePayout from "pages/AdminDashboard/Payout/ApprovePayout";
import RequestProduct from "pages/AdminDashboard/AddProduct/RequestProduct";
import ApproveProduct from "pages/AdminDashboard/AddProduct/ApproveProduct";

const ADMIN = "ADMIN", SELLER = "SELLER", USER = "USER"
const routes = [
  {
    name: "Main",
    layout: "/admin",
    path: "/main",
    icon: <img src={Home} width='20px' height='20px' color='inherit'/>,
    component: <MainDashboard/>,
    role: [ADMIN, SELLER]
  },
  {
    name: "Product",
    layout: "/admin",
    path: "/product",
    icon: (
      <img src={Product} width='20px' height='20px' color='inherit'/>
    ),
    component: <NFTMarketplace/>,
    secondary: true,
    role: [ADMIN, SELLER]
  },
  // {
  //   name: "Order",
  //   layout: "/admin",
  //   icon: <img src={Order} width='20px' height='20px' color='inherit'/>,
  //   path: "/order",
  //   component: DataTables,
  //   role: [ADMIN, SELLER]
  // },
  {
    name: "Profile",
    layout: "/admin",
    path: "/profile",
    icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
    component: <Profile/>,
    role: [ADMIN, SELLER]
  },
  {
    name: "Invoices",
    layout: "/admin",
    path: "/invoices",
    icon: <img src={Invoice} width='20px' height='20px' color='inherit'/>,
    component: <Invoices/>,
    role: [ADMIN]
  },
  {
    name: "Add Accounts",
    layout: "/admin",
    path: "/add-accounts",
    icon: <img src={AddAccounts} width='30px' height='20px' color='inherit'/>,
    component: <AddAccount/>,
    role: [ADMIN, SELLER]
  },
  {
    name: "Request Product",
    layout: "/admin",
    path: "/add-products",
    icon: <img src={RequestProductIcon} width='20px' height='20px' color='inherit'/>,
    component: <RequestProduct/>,
    role: [SELLER]
  },
  {
    name: "Approve Product",
    layout: "/admin",
    path: "/add-products",
    icon: <img src={RequestProductIcon} width='20px' height='20px' color='inherit'/>,
    component: <ApproveProduct/>,
    role: [ADMIN]
  },
  {
    name: "Add Products",
    layout: "/admin",
    path: "/add-products",
    icon: <img src={AddProducts} width='20px' height='20px' color='inherit'/>,
    component: <AddProduct/>,
    role: [ADMIN]
  },
  {
    name: "Edit Products",
    layout: "/admin",
    path: "/edit-products",
    icon: <img src={EditProducts} width='20px' height='20px' color='inherit'/>,
    component: <EditProduct/>,
    role: [ADMIN]
  },
  {
    name: "Members",
    layout: "/admin",
    path: "/members",
    icon: <img src={Member} width='20px' height='20px' color='inherit'/>,
    component: <Members/>,
    role: [ADMIN]
  },
  {
    name: "Transactions",
    layout: "/admin",
    path: "/transactions",
    icon: <img src={Transaction} width='20px' height='20px' color='inherit'/>,
    component: <Transactions/>,
    role: [ADMIN, SELLER]
  },
  {
    name: "Add Balance",
    layout: "/admin",
    path: "/add-balance",
    icon: <img src={AddBalances} width='20px' height='20px' color='inherit'/>,
    component: <AddBalance/>,
    role: [ADMIN]
  },
  {
    name: "Worker",
    layout: "/admin",
    path: "/worker",
    icon: <img src={WorkerIcon} width='20px' height='20px' color='inherit'/>,
    component: <Worker/>,
    role: [ADMIN]
  },
  {
    name: "Announcement",
    layout: "/admin",
    path: "/worker",
    icon: <img src={AnnouncementIcon} width='20px' height='20px' color='inherit'/>,
    component: <Announcement/>,
    role: [ADMIN]
  },
  {
    name: "Payment Methods",
    layout: "/admin",
    path: "/worker",
    icon: <img src={PaymentMethod} width='20px' height='20px' color='inherit'/>,
    component: <AddPaymentMethod/>,
    role: [SELLER]
  },
  {
    name: "Request Payout",
    layout: "/admin",
    path: "/worker",
    icon: <img src={RequestPayoutIcon} width='20px' height='20px' color='inherit'/>,
    component: <RequestPayout/>,
    role: [SELLER]
  },
  {
    name: "Payout History",
    layout: "/admin",
    path: "/worker",
    icon: <img src={WorkerIcon} width='20px' height='20px' color='inherit'/>,
    component: <PayoutHistory/>,
    role: [SELLER]
  },
  {
    name: "Approve Payout",
    layout: "/admin",
    path: "/worker",
    icon: <img src={WorkerIcon} width='20px' height='20px' color='inherit'/>,
    component: <ApprovePayout/>,
    role: [ADMIN]
  },
  
  // {
  //   name: "Sign In",
  //   layout: "/auth",
  //   path: "/sign-in",
  //   icon: <Icon as={MdLock} width='20px' height='20px' color='inherit' />,
  //   component: SignInCentered,
  // },
];

export default routes;