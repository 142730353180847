import {
  Flex,
  Table,
  Progress,
  Icon,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";

export default function ColumnsTable(props) {
  const { columnsData, tableData } = props;

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [id, setId] = useState("");
  const [username, setUsername] = useState("");
  const [balance, setBalance] = useState("");

  const handleOpen = (rowData) => {
    const { email, isAdmin, id, username, balance } = rowData;
    // Function logic to handle the open event
    setIsModalOpen(true);
    setEmail(email);
    setIsAdmin(isAdmin);
    setId(id);
    setUsername(username);
    setBalance(balance);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setEmail("");
    setIsAdmin(false);
    setId("");
    setUsername("");
    setBalance("");
  };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 5;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  return (
    <Card
      direction="column"
      w="100%"
      px="0px"
      overflowX={{ sm: "scroll", lg: "hidden" }}
    >
      <Flex px="25px" justify="space-between" mb="20px" align="center">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
        >
          Members List
        </Text>
        <Menu />
      </Flex>
      <Table
        {...getTableProps()}
        variant="simple"
        color="gray.500"
        mb="24px"
      >
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe="10px"
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    justify="space-between"
                    align="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="gray.400"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {page.map((row, index) => {
            prepareRow(row);
            const rowNumber = index + 1; // Add row number counter
            
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, index) => {

                  let data = "";
                  if (cell.column.Header === "#") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {rowNumber}
                      </Text>
                    );
                  } else if (cell.column.Header === "ID") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "USERNAME") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "BALANCE") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        $ {cell.value}
                      </Text>
                    );
                  } else if (cell.column.Header === "EMAIL") {
                    data = (
                      <Text color={textColor} fontSize="sm" fontWeight="700">
                        {cell.value}
                      </Text>
                    );
                    
                  } else if (cell.column.Header === "admin") {
                    data = (
                      <Flex align="center">
                        <Text color={textColor} fontSize="sm" fontWeight="700">
                          {cell?.value?.toString()}
                        </Text>
                        <Button
                          color="green"
                          appearance="primary"
                          onClick={() =>
                            handleOpen({
                              email: row.cells[4].value,
                              isAdmin: row.cells[5].value,
                              id: row.cells[1].value,
                              username: row.cells[2].value,
                              balance: row.cells[3].value,
                            })
                          }
                        >
                          <strong>Edit</strong>
                        </Button>
                        <Button
                          color="green"
                          appearance="primary"
                          onClick={() =>
                            handleOpen({
                              email: row.cells[4].value,
                              isAdmin: row.cells[5].value,
                              id: row.cells[1].value,
                              username: row.cells[2].value,
                              balance: row.cells[3].value,
                            })
                          }
                        >
                          <strong>Add Money</strong>
                        </Button>
                      </Flex>
                    );
                  }

                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={index}
                      fontSize={{ sm: "14px" }}
                      minW={{ sm: "150px", md: "200px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      {data}
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {isModalOpen && (
        <Modal isOpen={isModalOpen} onClose={handleClose} size={'xl'}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit User</ModalHeader>
            <ModalCloseButton />
            <ModalBody>

            <div className="popup-buy">
              <p>ID:</p>
              <Input className="user-input-rg" value={id} onChange={(e) => setId(e.target.value)} />
              </div>

              <div className="popup-buy">
              <p>Balance:</p>
              <Input className="user-input-rg"
                value={balance}
                onChange={(e) => setBalance(e.target.value)}
              />
              </div>

              <div className="popup-buy">
              <p>Username:</p>
              <Input className="user-input-rg"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              </div>

              <div className="popup-buy">
                <p>Email:</p>
                <Input className="user-input-rg" value={email} onChange={(e) => setEmail(e.target.value)} />
              </div>

            </ModalBody>
            <ModalFooter>
            <Button className="button-buy" appearance="primary">
            BUY
          </Button>

          <Button className="button-buy" appearance="primary">
            Close
          </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Card>
  );
}
