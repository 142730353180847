const Country = ({width, height, country}) => {

    const countryExists = () => {
            try {
                require(`./CountryIcon/${country}.png`)
                return true
            } catch {
                return false
            }
    }

    if (countryExists()) {
        return (
            <div className="flex justify-center items-center w-6 h-6 mr-2">
                <img src={require(`./CountryIcon/${country}.png`)} alt="" width={width} height={height}/>
            </div>
        )
    }  else {
        return (
            <div className="flex justify-center items-center w-6 h-6 mr-2">
            <img src={require(`./CountryIcon/CAMBODIA.png`)} alt="" width={width} height={height}/>
            </div>
        )
    }
}

   
Country.defaultProps = {
    country: 'CAMBODIA',
    width: 25,
    height: 25
};
export default Country


export const countries = [
        "CAMBODIA",
        "THAILAND",
        "US",
        "VIETNAM",
        "Brazil",
        "Mexico",
        "India"
    ]

export const COUNTRY_OPTIONS = countries.map(country => {
        return {label: <div className="flex"><Country country={country}/> {country}</div>, value: country}
})