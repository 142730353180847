import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Button, Modal } from 'rsuite';
import { get, post, API_URL } from '../../utils/API';


const one = {date: (new Date(1301090400 * 1000)).toLocaleDateString(), qty: 10, total: 10 * 0.0012, status: "COMPLETED"}
var data = []
for (let i = 0; i <= 10; i++) {
    data.push(one)
}  

export default function TransactionHistory() {
  const [transactions, setTransactions] = useState([])
  const [transactionDetails, setTransactionDetais] = useState([])
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState('static');
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      setOpen(false);
  }

  const getTransactions = async () => {
    var token = localStorage.getItem("hearhourshop")
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history?token=${token}`)
    var response = await get(`${API_URL}/profile/transaction-history?token=${token}`)
    setTransactions(response.transactions)
  }

  const viewTransactionDetails = async (transactionId) => {
    var token = localStorage.getItem("hearhourshop")
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history/view/${transactionId}?token=${token}`)
    var response = await get(`${API_URL}/profile/transaction-history/view/${transactionId}?token=${token}`)
    setTransactionDetais(response.transaction_details)
    handleOpen()
  }

  const exportTransactionDetails = async (transactionId) => {
    var token = localStorage.getItem("hearhourshop")
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history/export/${transactionId}?token=${token}`)
    // await fetch(`https://mmoshop.hearhour.repl.co/profile/transaction-history/export/${transactionId}?token=${token}`)
    // var response = await get(`http://localhost:8000/profile/transaction-history/export/${transactionId}?token=${token}`)
    await fetch(`${API_URL}/profile/transaction-history/export/${transactionId}?token=${token}`)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${transactionId}.txt`; // Specify the filename
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    getTransactions()
    var interval = setInterval(() => getTransactions(), 10000)
    return () => {
      clearInterval(interval)
    }
  }, [])

    return (
          <div className='body-user' >
          <div className='body-content pb-10'>
          <div className="grid items-center justify-center">
          <div className="col-span-4 lg:col-span-2">
                
        <TableContainer style={{ width: "100%" }}>
  <Table variant='simple'>
    <Thead>
      <Tr>
        <Th>Date</Th>
        <Th className='hidden md:table-cell'>Transaction ID</Th>
        <Th className='hidden md:table-cell'>Amount</Th>
        <Th className='hidden md:table-cell'></Th>
        <Th className='hidden md:table-cell'></Th>
      </Tr>
    </Thead>
    <Tbody>
      {transactions?.map((each) => (
        <Tr key={each.title}>
          <Td className=''>{each?.date}</Td>
          <Td className='hidden md:table-cell'>{each?.id}</Td>
          <Td className='hidden md:table-cell'>${parseFloat(each?.total).toFixed(3)}</Td>
          <Td className='hidden md:table-cell'><Button onClick={() => viewTransactionDetails(each?.id)}><img src="https://img.icons8.com/?size=512&id=CWkCzh9aKpqb&format=png" alt="" width={20} height={30}/>View</Button></Td>
          <Td className='hidden md:table-cell'><Button onClick={() => exportTransactionDetails(each?.id)}><img src="https://img.icons8.com/?size=512&id=104149&format=png" alt="" width={20} height={30}/>Export</Button></Td>
          <Modal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose} size="md" >
        <Modal.Header>
          <h5>Transaction Details</h5>
        </Modal.Header>

        <Modal.Body>
         {transactionDetails?.map(each => {
          return <p>{each.detail}</p>
         })}
        </Modal.Body>
        <Modal.Footer>
        <div className='grid grid-cols-2 items-center justify-center'>
          <Button className="button-buy" onClick={() => exportTransactionDetails(each?.id)} appearance="primary">
            Download .txt File
          </Button>
          <Button className="button-buy" onClick={handleClose} appearance="subtle">
            Cancel
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
        </Tr>
      ))}
    </Tbody>
  </Table>
</TableContainer>
    </div>
    </div>
    </div>
    </div>
    )
}