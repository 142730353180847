import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    } from '@chakra-ui/react'
import HearHour from "../../images/hearhour.png"
import { Button, Modal, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Routes, Route, Link, Navigate, BrowserRouter, useNavigate, redirect} from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import "./Dashboard.css"
import DashboardIcon from "../../images/dashboard-icon.png"
import LoginButton from 'components/Auth0/Login'
import LogoutButton from 'components/Auth0/Logout'


import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    } from '@chakra-ui/react'
import Scangroups from './Scangroups.jsx'
import CheckLiveUid from './Checkliveuid.jsx'
import GetUID from './GetUID.jsx'
import TransactionHistory from '../Tools/TransactionHistory.jsx'
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux';

export default function Dashboard() {
    const navigate = useNavigate()
    const user = useSelector(state => state.user)
    const { isAuthenticated, loginWithRedirect } = useAuth0()
    const ws = useRef(null)
    const [current, setCurrent] = useState({
        checkliveuid: true,
        scangroups: false,
        getuid: false,
    })

    const goToDashboard = () => {
        if (user.metadata.admin) {
            navigate("/admin")
            return
        }
        navigate("/dashboard")
    }

    const [open, setOpen] = useState(false);
    const [backdrop, setBackdrop] = useState('static');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const { logout } = useAuth0();


    function signout() {

        // setUser({loggedIn: false})
        localStorage.removeItem("hearhourshop")
        localStorage.removeItem("redirector")
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    function changeCurrent(keyToSet) {
        const data = {};
        for (const [key, value] of Object.entries(current)) {
        if (key === keyToSet) {
            data[key] = true;
            localStorage.setItem("redirector", keyToSet);
        } else {
            data[key] = false;
        }
        }
        setCurrent(data);
    }

    useEffect(() => {
        var currentPage = localStorage.getItem("redirector")
        if (!(currentPage === null)) {
            changeCurrent(currentPage)
        }
    }, []);

    return (
        <div className='items-start header-bg main-background scroll-main' style={{ position: 'relative', height: "100vh", width: "100vw", color:'black'}}>
        <div className='items-start' style={{ position: 'relative', justifyContent: "center", alignItems: "center"}}>
        <div className="cursor-pointer w-40 md:w-96" onClick={() => navigate("/")}>
            <img src={HearHour} alt="" width={250} />
        </div>
        
            
            {
            isAuthenticated ?
                <div className='p-1 space-x-3' style={{ position: "absolute", top: "1em", right: "1em" }}>
                <Button onClick={() => goToDashboard()}>
                    <img src={DashboardIcon} alt="" className='me-2 w-6 h-6'/>
                    Dashboard
                </Button>
                </div>
            
            :
            <div className='p-1 space-x-3' style={{ position: "absolute", top: "1em", right: "1em" }}>
                <LoginButton/>
            
                {/* <Button onClick={() => navigate("/login")}>Login</Button>
                <Button onClick={() => navigate("/register")}>Create an account</Button> */}
            </div>
            }
        </div>

        <div className='p-1 space-x-3' style={{ display:'flex', flexDirection:'row-reverse', marginRight:'15px' }}>
        <div className='text-gray-50 flex justify-center items-center py-2'>
        <Breadcrumb separator='|' className='main'>
            <BreadcrumbItem>
            <BreadcrumbLink href='/'>Home Page</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/mailcode')} >Mail Code</BreadcrumbLink>
            </BreadcrumbItem>


            <BreadcrumbItem>
            <BreadcrumbLink href='https://docs.mmoshop.me/' target="_blank">APIs Document</BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
        </div>
        </div>


        <div className="col-span-3">
            {/* user-dashboard */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}} className=''>
            <div className='1 rounded-xl text-black mt-15 md:m-10 background-svg' style={{width: "100vw", maxWidth:'1080px',minHeight:'750px'}}>
                <div className='flex menu'>
                <Breadcrumb className='text-sm md:text-base lg:text-lg' separator={"|"}>

                    <BreadcrumbItem onClick={() => changeCurrent("checkliveuid")}>
                    <BreadcrumbLink isCurrentPage={current.checkliveuid} className={current.checkliveuid ? 'active' : ''}>Check Live UID</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem onClick={() => changeCurrent("scangroups")}>
                    <BreadcrumbLink isCurrentPage={current.scangroups} className={current.scangroups ? 'active' : ''}>Scan Groups</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem onClick={() => changeCurrent("getuid")}>
                    <BreadcrumbLink isCurrentPage={current.getuid} className={current.getuid ? 'active' : ''}>Get UID</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem onClick={() => signout()}>
                    <BreadcrumbLink className={false ? 'active' : ''}>Sign Out</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
                </div>
                    {current.checkliveuid && <CheckLiveUid/>}
                    {current.scangroups && <Scangroups/>}
                    {current.getuid && <GetUID/>}
                </div>
            </div> 
        </div>
        </div>
    );
    }

