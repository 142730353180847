import React from "react";
import { useAuth0 } from "@auth0/auth0-react";

const LogoutButton = () => {
  const { logout } = useAuth0();

  function signout() {

    localStorage.removeItem("hearhourshop")
    localStorage.removeItem("redirector")
    logout({ logoutParams: { returnTo: window.location.origin }})
}

  return (
    <button onClick={() => signout()}>
      Log Out
    </button>
  );
};

export default LogoutButton;