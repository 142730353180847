import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "./ComplexTable";
import { columnsDataComplex } from "./columnsData";
import tableDataComplex from "./tableDataComplex.json";
import { useState , useEffect} from 'react';
import { API_URL, get } from "utils/API";
import { getLocalToken } from 'utils/token';
import { Input, Pagination } from 'rsuite';

export default function Invoices() {
  const [data, setData] = useState([])
  const [search, setSearch] = useState("")
  const [pagination, setPagination] = useState({
    current: 1,
    total: 1
  })

  const getInvoices = async () => {
    var token = getLocalToken()
    var response = await get(`${API_URL}/admin/get-invoices?page=${pagination.current}${search !== "" ? "&search=" + search : ""}`, token)
    if (response.code !== 200) {return}
    if (response.total_rows !== pagination.total) {
      setPagination({...pagination, total: response.total_rows})
    }
    setData(response.data)
  }

  useEffect(() => {
    getInvoices()
  }, [pagination])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          <div className="grid grid-cols-12">
            <div className="col-span-12 md:col-span-8 xl:col-span-6">
              <Input value={search} placeholder="Username or Transaction ID" onChange={(e) => setSearch(e)} onKeyDown={(e) => {
            if (e.key === "Enter") {
              getInvoices()
            }
          }}/>
            </div>
          </div>
     
        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={data}
        />
        <div className="flex justify-center items-center">
          <Pagination
          ellipsis
            prev
            last
            next
            first
            size="md"
            total={pagination.total}
            limit={20}
            activePage={pagination.current}
            onChangePage={(e) => setPagination({...pagination, current: parseInt(e)})}
          />
        </div>
      </SimpleGrid>
    </Box>
  );
}