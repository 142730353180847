import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import Country from 'components/Country/Country';
import { Button } from 'rsuite';
import SoldOut from "./icons/soldout.png"
import CopyLink from "../../images/copy.png"
import PlaceHolder from "./icons/placeholder.png"
import { useSelector } from 'react-redux';
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast";

export default function CategoryTable({ stocks, handleOpen }) {
  const [showMores, setShowMores] = useState(null);
  const user = useSelector(state => state.user)

  const toggleShowMore = (category) => {
    setShowMores((prevShowMores) =>
      prevShowMores.map((each) =>
        each.name === category ? { ...each, showMore: !each.showMore } : each
      )
    );
  };

  const shouldShow = (category) => {
    const showMoreCategory = showMores?.find((each) => each.name === category);
    return showMoreCategory ? showMoreCategory.showMore : false;
  };

  const shouldNotBeAbleToBuy = (stock) => {
    if (stock === 0 || user?.metadata?.role === "SELLER") {return true}
    if  ((!(stock > 0))) {return true}
    return false
  }

  const copyShareLink = async (each) => {
    var url = `http://api.mmoshop.me/store/redirector?product_id=${each.id}&category=`+ encodeURIComponent(each.category) + "&sid=" + uuid().slice(0, 5)
    navigator.clipboard.writeText(url.toString())
    /**Toast: Copied to clipboard */
    toast.success("Copied to clipboard", TOAST_OPTIONS)
    try {
      var response = await fetch(url) 
    } catch {
      return
    }


  }

  useEffect(() => {
    if (stocks?.categories) {
      setShowMores(
        stocks.categories.map((category) => ({ name: category, showMore: false }))
      );
    }
  }, [stocks]);

  return (
    <>
      {Array.isArray(stocks?.categories) &&
        stocks.categories.map((category) => (
          <div key={category}>
            <Table
              className='table-fixed col-span-1 lg:col-span-1 table-home max-w-[1150px]'
              variant='simple'
              style={{ borderColor: 'red' }}
            >
              <Thead>
                <Tr>
                <Th className='truncate hover:text-clip md:table-cell w-[210px] min-w-[210px] max-w-[210px]'>{category}</Th>
                  <Th className='hidden lg:table-cell truncate w-[550px] min-w-[550px] max-w-[550px]'>
                    Detail
                  </Th>
                  {/* <Th className='hidden lg:table-cell'>Date Created</Th> */}
                  <Th className='hidden md:table-cell'>Price/Account</Th>
                  <Th>In Stock</Th>
                  <Th style={{minWidth: "20%"}}></Th>
                </Tr>
              </Thead>
              <Tbody>
                {stocks.stocksByCategory?.get(category).slice(0, shouldShow(category) ? stocks.stocksByCategory.get(category).length : 5).map((each) => (
                  <Tr key={each.title}>
                    <Td className='items-start'>
                      <div className='flex items-center'>
                        <img
                          src={each.icon ? each.icon : PlaceHolder}
                          alt={each.name}
                          className='w-6 h-6 mr-2'
                        />
                        {each.name}
                      </div>
                      <div style={{width: "100%", overflow: "hidden", textOverflow: "ellipsis",}} className='break-all w-full wrap-text md:hidden'>
                        {/* <div className='flex items-center'>
                          <p className='inline-block'><Country country={each.country}/> {each.detail}</p>
                        </div> */}
                      </div>
                    </Td>
                    
                    <Td className='items-start hidden lg:table-cell '>
                      <div className='flex items-center'>
                        <div className='flex items-center'>
                          <Country country={each.country} />
                          <div className='whitespace-normal w-[500px] min-w-[500px] max-w-[500px]'>{each.detail}</div>
                        </div>
                      </div>
                    </Td>
                    {/* <Td className='hidden lg:table-cell'>
                      {each.date_created}
                    </Td> */}
                    <Td className='hidden md:table-cell'>
                      <dd className='chakra-stat__help-text css-gbp32c'>
                        ${parseFloat(each.price).toFixed(3)}
                      </dd>
                    </Td>
                    <Td>{each.stock > 0 ? each.stock : <img src={SoldOut} alt="Sold Out" width={30} height={30}/>}</Td>
                    <Td>
                      <div className='space-x-3 flex justify-center items-center'>
                        <Button color='green' appearance='primary' onClick={() => handleOpen(each)} disabled={shouldNotBeAbleToBuy(each.stock)}>
                          <strong>Buy</strong>
                        </Button>
                        <div className='hidden md:table-cell cursor-pointer hover:scale-110' onClick={() => copyShareLink(each)}>
                          <img src={CopyLink} alt="" width={30} height={30}/>
                        </div>
                    
                      </div>
                    </Td>
      
                  </Tr>
                ))}
              </Tbody>
            </Table>
            {
                (stocks.stocksByCategory.get(category).length >= 5) && (shouldShow(category) ? (
              <Button
                className='button-more'
                onClick={() => toggleShowMore(category)}
              >
                <img src="https://img.icons8.com/?size=512&id=h80UkgzZcDVa&format=png" width={30} height={30}/>
              </Button>
            ) : (
              <Button
                className='button-more'
                onClick={() => toggleShowMore(category)}
              >
                View All
              </Button>
            ))
            }
        
          </div>
        ))}
    </>
  );
}
