import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    } from '@chakra-ui/react'
import {get} from "../../utils/API.js"
import { useState, useEffect, useRef } from 'react'
import { API_URL } from '../../utils/API.js'
import { Heading, Highlight, CircularProgress, Textarea, Box } from '@chakra-ui/react';
import { Image } from '@chakra-ui/react'
import { Button, Modal, Placeholder, Input } from 'rsuite';
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Select,
} from '@chakra-ui/react'

export default function TopUp({user}) {
    const [invoices, setInvoices] = useState([])
    const ws = useRef(null)

    const [isLoading, setIsLoading] = useState(false);
    const [inputText, setInputText] = useState('');
    const [showText, setShowText] = useState(false);
    const [totalCount, setTotalCount] = useState('');
    const [dataRows, setDataRows] = useState([]);
    const [selectedOption, setSelectedOption] = useState('Facebook'); 
    const getInvoices = async () => {
      var token = localStorage.getItem("hearhourshop")
      if (token === null) {return}
    }

    const handleOptionChange = (event) => {
      setSelectedOption(event.target.value);
    };

    const handleStartClick = async () => {
      try {
        setIsLoading(true);
        const userInputLines = inputText.trim().split('\n');
    
        const rows = [];
        for (let i = 0; i < userInputLines.length; i++) {
          const userInput = userInputLines[i].trim();
    
          if (!userInput) {
            console.error('Error: User input is empty');
            continue;
          }
    
          let email = userInput.split('|')[0];
          let password = userInput.split('|')[1];
          let mailgetcode = userInput.split('|')[2];
              
          setDataRows(rows);
          setShowText(true);
          rows.push({
            row: (i + 1).toString(),
            email,
            password,
            mailgetcode,
            status: mailgetcode,
            content: 'Getting',
            verificationCode: '',
          });



          // console.log(selectedOption);


          if (selectedOption === 'Facebook') {
            // Modify the API URL for Facebook platform
            // const data = await get(`${API_URL}/profile/getcode-mail-microsoft?email=broadymirici@hotmail.com&password=x42J9S83`);
          }
    
          
    
          
        }

    
        for (let i = 0; i < rows.length; i++) {
          const row = rows[i];
          let encodedEmail = encodeURIComponent(row.mailgetcode);
          const data = await get(`${API_URL}/profile/getcode-mail-yandex?username=${row.email}&password=${row.password}&mailgetcode=${encodedEmail}`);
          const updatedRow = {
            ...row,
            content: data.Content,
            verificationCode: data.VerificationCode,
          };
    
          rows[i] = updatedRow;
          setDataRows([...rows]);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };
    
  
    useEffect(() => {
        getInvoices()
        const interval = setInterval(() => getInvoices(), 10000);
        return () => {
            clearInterval(interval);
        };
    }, [])

    return (
        <div className='body-user'>

            <div className='body-tools pb-10'>

              <div className='grid grid-cols-2' style={{display:'flex', alignItems:'center'}}>
                <div className='col-span-5 md:col-span-1'>
                <Heading lineHeight='tall'>
                    <Box fontSize='18px'> 
                    <Highlight
                        query='code'
                        styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.400'}}
                    >
                      Yandex Verification Code
                    </Highlight> {totalCount}
                    

                    
                    </Box>
                </Heading>
                </div>
                <div className='col-span-5 md:col-span-1'>
                          <Select className='select-items' value={selectedOption} onChange={handleOptionChange}>
                                  <option>Facebook</option>
                                  {/* <option>Intagram</option>
                                  <option>Tiktok</option> */}
                                </Select>
                      </div>
                      {isLoading && <CircularProgress isIndeterminate color='green.300' size='30px'/>}
                </div>
                
                <div className="grid grid-cols-1 items-center justify-center">
                <div className="col-span-1 lg:col-span-1" style={{ position: 'relative',margin:'20px 0' }}>
                    <div className="textarea" style={{backgroundColor:'#ffffff4f'}}>
                    <Textarea className="user-input scroll scroll4" placeholder="Username|Password|EmailGetcode" value={inputText} onChange={(e) => setInputText(e.target.value)}/>
                    </div>
                    <div className='sssssss'>
                        <Button className='button-start' onClick={handleStartClick}>Get Code</Button>
                        
                    </div>
                    
                </div>

                {showText && (
                <div className="col-span-1 lg:col-span-1 table-home shadow-md">
                <Table className='table-main' variant='simple'>
                  <Thead >
                    <Tr >
                      <Th className='hidden lg:table-cell'>No</Th>
                      <Th className='hidden lg:table-cell' >Username</Th>
                      <Th className='hidden md:table-cell'>Password</Th>
                      <Th >Mail Get Code</Th>
                      <Th className='hidden md:table-cell'>Status</Th>
                      <Th>Code</Th>
                      {/* <Th></Th> */}
                    </Tr>
                  </Thead>
                  <Tbody>
                  {dataRows.map((data, index) => (
                    <Tr key={index}>
                        <Td className='hidden lg:table-cell'>{data.row}</Td>
                        <Td className='hidden lg:table-cell'>{data.email}</Td>
                        <Td className='hidden md:table-cell'>{data.password}</Td>
                        <Td className=''>{data.status}</Td>
                        <Td className='hidden md:table-cell'>{data.content}</Td>
                        <Td style={{fontSize:'16px', color:'#009929', fontWeight:'700'}}>{data.verificationCode}</Td>
                        {/* <Td className='hidden md:table-cell'>
                          <Button color="green" appearance="primary" >
                            <strong>Retry</strong>
                          </Button>
                        </Td> */}
                      </Tr>
                    ))}
                  </Tbody>
                </Table>
            </div>
                )}
                </div>
            </div>
        </div>

    )
}