import { useState } from "react"
import { post , get} from "utils/API"

export default function useFetcher(props) {
    const [loading, setLoading] = useState(false)

    const FETCHER = async (url, method, body) => {
        setLoading(true)
        var response
        if (method === "POST") {
            try {
                response = await post(url, body)
                setLoading(false)
                return response
            }   catch {
                setLoading(false)
                return null
            }
        }
        if (method === "GET") {
            try {
                response = await get(url)
                setLoading(false)
                return response
            } catch {
                setLoading(false)
                return null
            }
        }
    
        setLoading(false)
        return response
    }

    return {loading, FETCHER}
}