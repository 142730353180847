import { useState, useEffect } from 'react';
import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';

export default function PayoutTable({ columnDef, columnData }) {
  return (
    <div className=''>
      <Table className='table-fixed table-home shadow-2xl' variant='simple'>
        <Thead>
          <Tr>
            {columnDef &&
              columnDef?.map((each, index) => {
                return <Th key={index}>{each.header}</Th>;
              })}
          </Tr>
        </Thead>
        <Tbody>
          {columnData &&
            columnData.map((entry, index) => {
              // Access nested properties
              const sellerPayout = entry['SellerPayout'];
              const sellerPaymentMethod = entry['SellerPaymentMethod'];

              return (
                <Tr key={index}>
                  {columnDef.map((dataDef, dataIndex) => {
                    // Use the dot notation to access the nested properties
                    const value = dataDef.accessor.includes('SellerPayout')
                      ? sellerPayout[dataDef.accessor.split('.')[1]]
                      : sellerPaymentMethod[dataDef.accessor.split('.')[1]];

                    return (
                      <Td key={dataIndex} style={dataDef?.style}>
                        {dataDef?.prefix} {value} {dataDef?.suffix}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
        </Tbody>
      </Table>
    </div>
  );
}

