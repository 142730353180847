
import './App.css';
import 'rsuite/dist/rsuite.min.css';
import 'react-loading-skeleton/dist/skeleton.css'

import { Routes, Route, Link, Navigate, BrowserRouter, HashRouter } from "react-router-dom";
import Main from './pages/Main/Main.jsx';
import Login from './pages/Login/Login.jsx';
import Register from './pages/Register/Register.jsx';
import Dashboard from './pages/Dashboard/Dashboard.jsx';
import Tools from './pages/Tools/Dashboard.jsx';
import Mailcode from './pages/Mailcode/Dashboard.jsx';
import AdminLayout from "./layouts/admin";
import { useState, useCallback, useEffect } from 'react';
import { post, API_URL, get} from './utils/API';
import { useAuth0 } from "@auth0/auth0-react";
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'redux/user';
import { getLocalToken } from 'utils/token';
import axios from 'axios';

function App() {
  const dispatch = useDispatch()
  const user = useSelector(state => state.user)

  const { user: User , isAuthenticated, getIdTokenClaims, getAccessTokenSilently} = useAuth0();
  
  useEffect(() => {
    const getUserMetadata = async () => {
      const domain = "dev-v7hs14v5nd5geajo.us.auth0.com";
  
      try {
        const id_token = await getIdTokenClaims()
        var token
        try {
          localStorage.setItem("hearhourshop", id_token.__raw)
          token = id_token.__raw
        } catch {
          token = getLocalToken()
        }
        
        if (token === null || token === "" || token === undefined) {return}
        var response

        try {
          // console.log("Called from here")
          response = await axios.get(`${API_URL}/profile`, {headers: {"Authorization": "Bearer " + token}})
        } catch {
          return
        }

        if (response?.data?.code !== 200) {return}
        dispatch(userActions.updateUserMetadata(response.data.profile))
      } catch (e) {
        console.log(e.message);
      }
    };

    getUserMetadata()
  }, [isAuthenticated]); //getIdTokenClaims, user?.sub

  const getUserProfile = useCallback(async () => {
    var response
    var token = getLocalToken()
    if (token === null || token === undefined || token === "") {return}
    try {
      response = await axios.get(`${API_URL}/profile`, {headers: {"Authorization": "Bearer " + token}})
    } catch {
      return
    }

    if (response?.data?.code !== 200) {return}
    dispatch(userActions.updateUserMetadata(response.data.profile))
  }, [])

  // useEffect(() => {
  //   const getUserMetadata = async () => {
  //     const domain = "dev-v7hs14v5nd5geajo.us.auth0.com";
  
  //     try {
  //       const id_token = await getIdTokenClaims()
  //       var token
  //       try {
  //         localStorage.setItem("hearhourshop", id_token.__raw)
  //         token = id_token.__raw
  //       } catch {
  //         token = getLocalToken()
  //       }
        
  //       if (token === null) {return}
     
  //       var response = await get(`${API_URL}/profile`, token)
  //       if (response.code !== 200) {return}
  //       dispatch(userActions.updateUserMetadata(response.profile))
  //       console.log("Response")
  //       console.log(response.profile)
  //     } catch (e) {
  //       console.log(e.message);
  //     }
  //   };
  //   getUserMetadata();
  // }, [])


  // const loginWIthToken = useCallback(async () => {
  //   var token = localStorage.getItem("hearhourshop")
  //   if (token === "") {return}
  //   if (token === undefined) {return}
  //   if (token === null) {token = "undefined"}
  //   var response = await post(`${API_URL}/login`, {token: token})
  //   if (response.code !== 200) {return}
  //   setUser({
  //     loggedIn: true,
  //     metadata: response.metadata
  //   })
  // }, [])

  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/" element={<Main getUserProfile={getUserProfile}/>}/>
          {/* <Route path="/login" element={<Login user={user} setUser={setUser}/>} />
          <Route path="/register" element={<Register user={user} setUser={setUser}/>} /> */}
          <Route path="/dashboard" element={<Dashboard/>} />
          <Route path="/tools" element={<Tools/>} />
          <Route path="/mailcode" element={<Mailcode/>} />
          <Route path="/admin" element={<AdminLayout getUserProfile={getUserProfile}/>}/>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
