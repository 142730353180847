import ABA from "../icons/aba.png"
import PayPal from "../icons/paypal.jpg"
import { Card, CardHeader, CardBody, CardFooter, Text } from '@chakra-ui/react'
import { useDisclosure } from "@chakra-ui/react"
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
    ModalFooter,
    ModalBody,
  } from "@chakra-ui/react";
import axios from "axios";
import { useEffect, useState } from "react";
import { Button, Input } from "rsuite";
import { API_URL } from "utils/API";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast";

const PAYMENT_OPTIONS = [
    {
        method: "ABA",
        icon: ABA,
        inputLabel: "ABA Account",
        placeholder: "Ex : 010888461|HEANGLYHOUR"
    },
    {
        method: "PayPal",
        icon: PayPal,
        inputLabel: "Email or Phone Number",
        placeholder: "Ex : email@gmail.com or 016......."
    }
]

export default function AddPaymentMethod() {
    const [isOpen, setIsOpen] = useState(false)
    const [current, setCurrent] = useState(null)
    const [paymentOptions, setPaymentOptions] = useState([])


    const onOpen = (paymentOption) => {
     
        paymentOptions.forEach(each => {
            if (each.payment_type === paymentOption.method) {
                paymentOption["username"] = each.payment_address
      
            }
        })

        setCurrent(paymentOption)
        setIsOpen(true)
    }

    const onClose = () => {
        setIsOpen(false)
        setCurrent(null)
    }

    const savePaymentOption = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.get(`${API_URL}/seller/payment-method/add?method=${current.method}&username=${current.username}`, {headers: {"Authorization": `Bearer ${token}`}})

        if (response?.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }

        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchPaymentOptions()
    }

    // useEffect(() => console.log(paymentOptions))

    const fetchPaymentOptions = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.get(`${API_URL}/seller/payment-method/get`, {headers: {"Authorization": `Bearer ${token}`}})

        if (response.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }

        toast.success(response?.data?.message, TOAST_OPTIONS)
        setPaymentOptions(response.data.payment_methods)
    }

    useEffect(() => {
        fetchPaymentOptions()
    }, [])

    return (
        <div> 
            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
            />

            <div className="grid grid-cols-12 space-x-4 mt-5">
                {PAYMENT_OPTIONS.map((each) => {
                    return (
                    <div className="col-span-2" onClick={() => onOpen(each)}>
                        <Card className="h-full shadow-2xl cursor-pointer ">
                            <CardBody>
                                <div className="flex items-center justify-center w-full" style={{height: "100%"}}>   
                                    <img src={each?.icon} alt="" />
                                </div>
                            </CardBody>
                        </Card>
                    </div>
                    )
                })}
            </div>

                <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{current?.method}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody pb={6}>
                        <p>{current?.inputLabel} <Input onChange={(e) => setCurrent({...current, username: e})} placeholder={current?.placeholder}/></p>
                    </ModalBody>
                    <ModalFooter>
                    <div className="space-x-4">
                        <Button colorScheme='blue' mr={3} appearance="primary" color="green" onClick={() => savePaymentOption()}>Save</Button>
                        <Button onClick={onClose}>Cancel</Button>
                    </div>
               
                    </ModalFooter>
                </ModalContent>
                </Modal>
           
        </div>
    )
}