import PatternTable from "views/admin/default/Table";
import { useCallback, useEffect, useState } from "react";
import { getLocalToken } from "utils/token";
import { API_URL } from "utils/API";
import axios from "axios";
import { Button } from "rsuite";
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast"

const colDef = [
    {
        header: "OWNER",
        accessor: "username"
    },
    {
        header: "NAME",
        accessor: "name"
    },
    {
        header: "CATEGORY",
        accessor: "category"
    },
    {
        header: "DETAIL",
        accessor: "detail"
    },
    {
        header: "COUNTRY",
        accessor: "country"
    },
    {
        header: "PRICE",
        accessor: "price"
    },
    {
        header: "ICON",
        accessor: "icon"
    },
    {
        header: "APPROVE",
        accessor: "approve"
    },
    {
        header: "DENY",
        accessor: "deny"
    }
]

export default function ApproveProduct() {
    const [requests, setRequests] = useState([])

    const approveRequest = async (request) => {
        var token = getLocalToken()
        try {
            var response = await axios.get(`${API_URL}/admin/approve-product/${request.id}`, {headers: {"Authorization": `Bearer ${token}`}})

            if (response?.status !== 200) {
                toast.error(response?.data?.message, TOAST_OPTIONS)
                return 
            }
    
            fetchRequests()
            toast.success(response?.data?.message, TOAST_OPTIONS)
        } catch {
            return
        }
    
    }

    const denyRequest = async (request) => {
        var token = getLocalToken()
        try {
            var response = await axios.get(`${API_URL}/admin/deny-product/${request.id}`, {headers: {"Authorization": `Bearer ${token}`}})
        
            if (response?.status !== 200) {
                toast.error(response?.data?.message, TOAST_OPTIONS)
                return 
            }
    
            fetchRequests()
            toast.success(response?.data?.message, TOAST_OPTIONS)
        } catch {
            return
        }
    }

    const fetchRequests = async () => {
        var token = getLocalToken()
        try {
            var response = await axios.get(`${API_URL}/admin/get-product-requests`, {headers: {"Authorization": `Bearer ${token}`}})
        
            if (response?.status !== 200) {
                toast.error(response?.data?.message, TOAST_OPTIONS)
                return 
            }
    
            var requests = response?.data?.product_requests.map(request => {
                request["approve"] = <Button appearance="primary" color="blue" onClick={() => approveRequest(request)}>Approve</Button>
                request["deny"] = <Button appearance="primary" color="red" onClick={() => denyRequest(request)}>Deny</Button>
                return request
            })
            setRequests(requests)
            toast.success(response?.data?.message, TOAST_OPTIONS)
        } catch {
            return
        }
    }

    useEffect(() => {
        fetchRequests()
    }, [])

    return (
        <div>
          <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
              />
            <PatternTable columnData={requests && requests} columnDef={colDef}/>
        </div>
    )
}