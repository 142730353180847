import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'
import QRCode from 'qrcode.react';
import { css } from '@emotion/react';
import { Button } from 'rsuite';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton } from '@chakra-ui/react';
import { ReactComponent as LoadingSVG } from '../../images/loading1.svg';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../../utils/toast'
import { v4 as uuid } from 'uuid';

import {
    Input,
    InputGroup,
    InputAddon,
    InputLeftAddon,
    InputRightAddon,
    InputElement,
    InputLeftElement,
    InputRightElement,
} from "@chakra-ui/input"


import { BakongKHQR, khqrData, IndividualInfo, MerchantInfo, SourceInfo } from "bakong-khqr";
import ABA_QR from "../../images/ABA_QR.png"
import KHQR from "../../images/KHQR.png"
import KHQR_background from "../../images/KHQR_background.png"
import PAYPAL from "../../images/PAYPAL.png"
import logo_khqr from "../../images/logo-khqr.png"
import visacard from "../../images/visacard.png"
import payway from "../../images/Logo_payway.png"
import { get } from "../../utils/API.js"
import { useState, useEffect, useRef } from 'react'
import { API_URL, WS_URL } from '../../utils/API.js'
import { getLocalToken } from 'utils/token'
import { useSelector } from 'react-redux'
import html2canvas from 'html2canvas';



export default function TopUp() {
    const user = useSelector(state => state.user)
    const [invoices, setInvoices] = useState([])
    const ws = useRef(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isScanned, setIsScanned] = useState(false);
    const [countdown, setCountdown] = useState(180);
    const [isLoading, setIsLoading] = useState(false);
    const [amount, setAmount] = useState('');
    const timerRef = useRef();
    const [KHQRStringtest, setKHQRStringtest] = useState('');
    const [qrCodeImage, setQRCodeImage] = useState(null);
    const [md5, setMd5] = useState("")
    
    const wsRef = useRef(null)

    // useEffect(() => {
    //     if (isModalOpen) {
    //         const { BakongKHQR, khqrData } = require("bakong-khqr");
    //         const optionalData = {
    //             currency: khqrData.currency.usd,
    //             amount: parseFloat(amount).toFixed(2),
    //             mobileNumber: "85510888461",
    //             storeLabel: "MMOSHOP",
    //             terminalLabel: "MMOSHOP_Invoices",
    //             purposeOfTransaction: "oversea",
    //             languagePreference: "km",
    //             merchantNameAlternateLanguage: "Heang",
    //             merchantCityAlternateLanguage: "Lyhour",
    //             upiMerchantAccount: "",
    //         };
    //         console.log('Amount in useEffect:', amount); // Add this line to check the 'amount' value in useEffect
    
    //         const individualInfo = new IndividualInfo(
    //             "heang_lyhour@aclb",
    //             "MMO SHOP",
    //             "PHNOM PENH",
    //             optionalData
    //         );
    
    //         const khqr = new BakongKHQR();
    //         const response = khqr.generateIndividual(individualInfo);
    
    //         const KHQRString = JSON.stringify(response.data.qr);
    //         const KHQR_md5 = JSON.stringify(response.data.md5);
    //         const KHQRString1 = KHQRString.replace(/^"(.*)"$/, "$1");
    //         setKHQRStringtest(KHQRString1);
    //         setMd5(KHQR_md5)
    //         console.log(KHQR_md5);
    //     }
       
    // }, [isModalOpen]);


    const qrCodeCanvasRef = useRef(null);
    const handleAmountChange = (event) => {
        const inputValue = event.target.value;

        // Validate the input to ensure it is a valid number and greater than or equal to 1
        const isValidNumber = /^\d+(\.\d{0,2})?$/.test(inputValue);
        const isGreaterThanOne = parseFloat(inputValue) >= 1.00;

        if (isValidNumber && isGreaterThanOne) {
            // Update the 'amount' state only if the input is a valid number and greater than or equal to 1
            setAmount(inputValue);
        } else {
            // Display an alert if the input is not a valid number or less than 1
            toast.error('Please enter a valid amount greater than or equal to 1.00', TOAST_OPTIONS);
            // alert("Please enter a valid amount greater than or equal to 1.0");
        }
    };

    const formatAmount = (value) => {
        const parsedValue = parseFloat(value);
        if (!isNaN(parsedValue)) {
            return parsedValue.toFixed(2);
        }
        return "";
    };

    const getInvoices = async () => {
        var token = getLocalToken()
        //   var response = await get(`https://mmoshop.hearhour.repl.co/profile/get-invoices?token=${token}`)
        var response = await get(`${API_URL}/profile/get-invoices`, token)
        setInvoices(response.invoices)
    }


    useEffect(() => {
        getInvoices()
        const interval = setInterval(() => getInvoices(), 10000);
        return () => {
            clearInterval(interval);
        };
    }, [])

    useEffect(() => {
        return () => {
            clearTimeout();
        };
    }, []);


    const startCountdown = () => {
        timerRef.current = setInterval(() => {
            setCountdown((prevCountdown) => prevCountdown - 1);
        }, 1000); // Decrease countdown every second (1000 milliseconds)

        setTimeout(() => {
            setIsModalOpen(false);
            setIsScanned(false); // Reset the scanning status
            clearInterval(timerRef.current); // Clear the timer when the countdown is complete
            setCountdown(180); // Reset the countdown to 180 seconds for the next scan
        }, 180000); // 180000 milliseconds = 3 minutes
    };

    useEffect(() => {
        if (isModalOpen && !isScanned) {
            startCountdown();
        } else {
            clearInterval(timerRef.current); // Clear the timer when the modal is closed
            setCountdown(180); // Reset the countdown when the modal is closed
        }
    }, [isModalOpen, isScanned]);

    useEffect(() => {
        if (isModalOpen && user?.metadata) {
            const { BakongKHQR, khqrData } = require("bakong-khqr");
            const optionalData = {
                currency: khqrData.currency.usd,
                amount: parseFloat(amount).toFixed(2),
                mobileNumber: "85510888461",
                storeLabel: "MMOSHOP",
                terminalLabel: "MMOSHOP_Invoices",
                purposeOfTransaction: "oversea",
                languagePreference: "km",
                merchantNameAlternateLanguage: "Heang",
                merchantCityAlternateLanguage: "Lyhour",
                upiMerchantAccount: "",
            };
            // console.log('Amount in useEffect:', amount); // Add this line to check the 'amount' value in useEffect
    
            const individualInfo = new IndividualInfo(
                "heang_lyhour@aclb",
                "MMO SHOP",
                "PHNOM PENH",
                optionalData
            );
    
            const khqr = new BakongKHQR();
            const response = khqr.generateIndividual(individualInfo);
    
            const KHQRString = JSON.stringify(response.data.qr);
            const KHQR_md5 = JSON.stringify(response.data.md5);
            const KHQRString1 = KHQRString.replace(/^"(.*)"$/, "$1");
            setKHQRStringtest(KHQRString1);
            setMd5(KHQR_md5)
            // console.log(KHQR_md5);

            var sid = uuid()
            wsRef.current = new WebSocket(`${WS_URL}/topup?username=${user?.metadata?.username}&sid=${sid}&md5=${KHQR_md5}`)
            
            wsRef.current.onmessage = (event) => {
                if (event.data === "verified") {
                    setIsModalOpen(false)

                }
            }
            return () => {
                if (wsRef.current) {
                    wsRef.current.close()
                }
            }
        }
     
    }, [isModalOpen])

    const saveQRCodeImage = async () => {
        // const canvas = qrCodeCanvasRef.current;

        // if (canvas && canvas instanceof HTMLCanvasElement) {
        //     try {
        //         canvas.toBlob((blob) => {
        //             if (blob) {
        //                 const url = URL.createObjectURL(blob);
        //                 const link = document.createElement('a');
        //                 link.href = url;
        //                 link.download = 'qr_code_with_background.png';
        //                 document.body.appendChild(link);
        //                 link.click();
        //                 document.body.removeChild(link);
        //                 URL.revokeObjectURL(url); // Release the Object URL
        //             } else {
        //                 console.error("Failed to create Blob from canvas.");
        //             }
        //         }, 'image/png');
        //     } catch (error) {
        //         console.error("Error while saving QR code image:", error);
        //     }
        // } else {
        //     console.error("Canvas reference is not valid.");
        // }


        const element = qrCodeCanvasRef.current;
        const canvas = await html2canvas(element);

        const data = canvas.toDataURL('image/jpg');
        const link = document.createElement('a');

        if (typeof link.download === 'string') {
            link.href = data;
            var unixTimestamp = Math.floor(Date.now() / 1000).toString()
            link.download = `${unixTimestamp}.jpg`;

            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            window.open(data);
        }
    };




    const handleKHQRImageClick = (amount) => {
        // Check if the amount is NaN
        // console.log(amount)
        if (isNaN(parseFloat(amount))) {
            // Display an alert if amount is NaN
            toast.error('Please enter a valid amount.', TOAST_OPTIONS);
            // alert("Please enter a valid amount.");
            // toast.error("Please enter a valid amount.", TOAST_OPTIONS);
            return;
        }

        // Continue with the rest of your logic if the amount is valid
        const inputValue = parseFloat(amount).toFixed(2);
        setAmount(inputValue);
        setIsModalOpen(true);
        setIsLoading(true);
    };

    var PAYMENT_OPTIONS = [
        {
            name: "KHQR",
            image: KHQR,
            selected: false,
            next: handleKHQRImageClick
        }, 
        {
            name: "PAYPAL",
            image: PAYPAL,
            selected: false,
            next: null
        }, 
        {
            name: "ABA_QR",
            image: visacard,
            selected: false,
            next: null
        }
    ]
    const [paymentOptions, setPaymentOptions] = useState(PAYMENT_OPTIONS)

    const changePaymentSelection = (payment) => {
        setPaymentOptions(paymentOptions.map((each) => {
            if (each !== payment) {
                return {...each, selected: false}
            } else {
                return {...each, selected: true}
            }
        }))
    }

    const nextAction = () => {
        if (Array.isArray(paymentOptions)) {
            paymentOptions.forEach(payment => {
                if (payment.selected && payment.next) {
                    payment.next(amount)
                }
            })
        }
    }

    return (
        <div className='body-user-topup'>

            <div className='body-content pb-10 '>
                <div className="grid items-center justify-center">
                    <div className="">
                        <div className='info-user'>
                            <div class="info-item">
                                <div class="deposit-title">
                                    <p class="deposit-title">Choose Payment <span class="text-aba">Method</span></p>
                                </div>
                                <div class="deposit-content ">

                                    <InputGroup>
                                        <InputLeftElement
                                            pointerEvents='none'
                                            color='gray.300'
                                            fontSize='1.2em'
                                            children='$'
                                        />
                                        <Input placeholder='Enter amount' value={amount} onChange={(e) => handleAmountChange(e)} />
                                    </InputGroup>


                                    {/* <h6 className='text-content-topup'>* Choose payment method</h6> */}

                                    <div className='pyment-method grid grid-cols-3'>
                                        {paymentOptions.map((payment) => {
                                            return <div className={payment?.selected ? "bg-lime-400" : "bg-white"} onClick={() => changePaymentSelection(payment)}><img className="payment-logo" src={payment.image} alt="" width={100}/></div>
                                        })}
                                        {/* <img className="payment-logo" src={KHQR} alt="" width={100} onClick={handleKHQRImageClick}/>
                                    
                                        <img className="payment-logo" src={ABA_QR} alt="" width={100} onClick={() => null}/> */}
                                    </div>

                                    <div>
                                        <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} isCentered closeOnOverlayClick={false}>
                                            <button onClick={() => setIsModalOpen(true)}>Open Modal</button>
                                            {isModalOpen && (
                                                <div>
                                                    <p>Modal content here</p>
                                                    <button onClick={() => setIsModalOpen(false)}>Close Modal</button>
                                                </div>
                                            )}
                                            <ModalOverlay />
                                            <ModalContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                <ModalHeader className='header-qr' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '90%' }}>
                                                    <div className='header-item1'>
                                                        <img className="items-left" src={logo_khqr} alt="" width={200} />

                                                    </div>
                                                    <div style={{ display: 'flex', alignItems: 'center' }}>

                                                        {isLoading ? (
                                                            <div style={{ animation: 'rotate 2s linear infinite' }}>
                                                                <LoadingSVG style={{ width: 30, height: 30 }} />
                                                            </div>
                                                        ) : (
                                                            <div>{Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}{countdown % 60}</div>
                                                        )}
                                                        <div>{Math.floor(countdown / 60)}:{countdown % 60 < 10 ? '0' : ''}{countdown % 60}</div>
                                                        <ModalCloseButton style={{ marginTop: '10px', color: 'green' }} />
                                                    </div>
                                                </ModalHeader>

                                                <ModalHeader><p className="amount-value"> {formatAmount(amount)} <span className="amount-currency-qr">USD</span></p></ModalHeader>

                                                <ModalBody
                                                    style={{
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                        justifyContent: 'center',
                                                        border: '20px', // Remove border
                                                        //   padding: 0, // Remove padding
                                                        width: '100%', // Allow the content to take full width
                                                        height: '100%', // Allow the content to take full height
                                                        backgroundColor: '#f6f6f6',
                                                    }}
                                                >
                                                    <div
                                                        ref={qrCodeCanvasRef}
                                                        className="scan-to-pay"
                                                        style={{
                                                            position: 'relative', // Needed for absolute positioning of the background
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            justifyContent: 'center',
                                                            height: '450px',
                                                            width: '100%',
                                                            backgroundColor: '#f6f6f6',
                                                            backgroundImage: `url(${KHQR_background})`,
                                                            backgroundSize: 'cover',
                                                            backgroundPosition: 'center',
                                                        }}
                                                    >
                                                        <div
                                                            className="qr-code-canvas"
                                                            style={{
                                                                position: 'absolute',
                                                                top: 125,
                                                                left: 0,
                                                                right: 0,
                                                                bottom: 0,
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                backgroundColor: 'transparent',
                                                            }}
                                                        >
                                                            <QRCode value={KHQRStringtest} size={200} bgColor="transparent" />
                                                        </div>
                                                    </div>
                                                    <Button onClick={() => saveQRCodeImage()} appearance="primary" color="cyan" className='m-5'>Save Image</Button>


                                                </ModalBody>
                                            </ModalContent>
                                        </Modal>
                                        {!isScanned && (
                                            <div id="scanner" style={{ width: '100%', }}></div>
                                        )}
                                        {isScanned && <p>QR code has been scanned successfully!</p>}
                                    </div>
                                    <Button className="btb-nextqr" onClick={() => nextAction()}>
                                        Next
                                    </Button>

                                    {/* <p className='text-content-topup'>Please transfer with remark <span class="text-remark">{user?.metadata?.username}</span> to the account below The system will automatically add money after 1 to 5 minutes</p> */}
                                </div>
                                <div class="deposit-contact ">
                                    <p className='text-content-topup'>សូមចំណាំ : ត្រូវស្កែន QR CODE ថ្មីរហូតដែល Website លោតអោយ។ ហាម Scan QR CODE ដដែរៗ​​​  បើស្កែនហើយអត់ឃើញលុយចូលទាក់ទងតាម Telegram <a class="text-linktelegram" href="https://t.me/hearhour" target="_blank"> HEAR HOUR</a> for support | បើសិនអត់យល់សូមមើល Video របៀបដាក់លុយ​ <a class="text-linkyoutube" href="https://youtu.be/GCYHTNmJ3Ok" target="_blank"> VIDEO</a></p>
                                    
                                </div>
                                
                            </div>
                            <div className="col-span-4 lg:col-span-2 tablemain">
                                <TableContainer className='tablesr scroll5 table-con' style={{ width: "100%", height: '220px', overflowX: 'hidden', overflowY: 'auto', paddingTop: '0px' }}>
                                    <Table variant='simple'>
                                        <Thead>
                                            <Tr>
                                                <Th className='thb'><p className='table-topup-p'>Amount</p> </Th>
                                                <Th className='thb'><p className='table-topup-p'>Date</p> </Th>
                                                <Th className='thb hidden md:table-cell'><p className='table-topup-p'>Status</p> </Th>

                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {invoices?.map((each) => {
                                                const date = new Date(each?.date);
                                                const formattedDate = date.toLocaleString();
                                                return (
                                                    <Tr>
                                                        <Td className='ui-td'>${each?.amount}</Td>
                                                        <Td className='ui-td'>{formattedDate}</Td>
                                                        <Td className='ui-td hidden md:table-cell'>{each?.status}</Td>
                                                    </Tr>
                                                )
                                            })}

                                        </Tbody>

                                    </Table>
                                </TableContainer>
                            </div>


                            <div >
                                <div  >
                                </div>
                            </div>

                        </div>
                    </div>
                </div>


            </div>

            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
            />
        </div>
    )
}