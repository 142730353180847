import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  metadata: null,
}

export const counterSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateUserMetadata: (state, action) => {
        state.metadata = action.payload
    }
  },
})

export const userActions = counterSlice.actions
export const userReducer = counterSlice.reducer