import { Input, InputGroup, Button } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { Wrap, WrapItem, Avatar } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { get, API_URL } from '../../utils/API';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from 'redux/user';
import { getLocalToken } from 'utils/token';


export default function Profile() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const [visible, setVisible] = useState(false);

    const handleChange = () => {
        setVisible(!visible);
    };

    const changeAPIKey = async () => {
        var token = getLocalToken()
        var response = await get(`${API_URL}/profile/change-api-key`, token)
        var oldMetadata = user?.metadata
        oldMetadata = {...oldMetadata, api_key: response.new_api_key}
        dispatch(userActions.updateUserMetadata(oldMetadata))
    }

    const getBalance = async () => {
        var token = getLocalToken()
        var response = await get(`${API_URL}/profile/get-balance`, token)
        var oldMetadata = user?.metadata
        oldMetadata = {...oldMetadata, balance: response.balance}
        dispatch(userActions.updateUserMetadata(oldMetadata))
    }

    // useEffect(() => {
    //     const interval = setInterval(() => getBalance(), 10000);
    //     return () => {
    //         clearInterval(interval);
    //     };
    // })


    return (
        <div className='body-user'>
        <div className='body-content'>
            <div className='grid grid-cols-5'>
                <div className='col-span-5 md:col-span-3'>
                <div className='info-user'>
                <div className="item-info">
                    <div className="par-title">
                        <p>Username</p>
                    </div>
                    <div className="par-content">
                        <p>{user.metadata?.username}</p>
                    </div>
                </div>

                <div className="item-info">
                    <div className="par-title">
                        <p>Email</p>
                    </div>
                    <div className="par-content">
                        <p>{user.metadata?.email}</p>
                    </div>
                </div>

                <div className="item-info">
                    <div className="par-title">
                        <p>Api Key</p>
                    </div>
                    <div className="par-content">
                        <InputGroup  className=''>

                            <Input type={visible ? 'text' : 'password'} value={user?.metadata?.api_key}/>
                            <InputGroup.Button onClick={handleChange}>
                                {visible ? <EyeIcon /> : <EyeSlashIcon />}
                            </InputGroup.Button>
       
                            <Button className='' onClick={() => changeAPIKey()}>Change</Button>
                        </InputGroup>
               

                    </div>
                    <div className="button-change-api">
                        {/* <Button color="green" appearance="primary" style={{height:'28px'}} onClick={() => changeAPIKey()}><strong>Change</strong> </Button> */}
                    </div>
                    
                </div>
           </div>
                </div>
                <div className="col-span-5 md:col-span-2">
                <div className='info-balance'>


                <div className='profile-info'>
                    <div className="intem-balance">
                    <div className='profile' style={{ display: 'flex', alignItems: 'center',marginTop:'20px' }}>
                        <Wrap>
                            <WrapItem>
                                <Avatar size='lg' name='Hear Hour' src={user.metadata?.picture} />
                            </WrapItem>
                        </Wrap>
                    <div className="balance-par-title">
                        <strong className='price-detail-username text-black'>{user.metadata?.username}</strong>
                    </div>
                        {/* <strong className='price-detail'>Hello, {user.metadata?.username}</strong> */}
                    </div>
                    </div>

                    <div className="intem-balance">
                    <div className="balance-par-title">
                        <strong className='price-detail text-black'>Balance</strong>
                    </div>
                    <div className="par-Balance">
                        <strong className='price-show'>$ {user.metadata?.balance.toFixed(3)}</strong>
                    </div>
                    </div>
                </div>
</div>

                </div>
            </div>
         
      
        </div>

       
    </div>
    )
}