import { Box, SimpleGrid } from "@chakra-ui/react";
import ComplexTable from "./ComplexTable";
import { columnsDataComplex } from "./columnsData";
import { useState , useEffect} from 'react';
import { API_URL, get } from "utils/API";
import { getLocalToken } from 'utils/token';

export default function Invoices() {
  const [data, setData] = useState([])

  useEffect(() => {

    const getInvoices = async () => {
      var token = getLocalToken()
      var response = await get(`${API_URL}/admin/get-users`, token)
      if (response.code !== 200) {return}
      setData(response.data)
      
      
    }
    getInvoices()
  }, [])

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>

        <ComplexTable
          columnsData={columnsDataComplex}
          tableData={data}
        />
      </SimpleGrid>
    </Box>
  );
}