// Chakra imports
import { Avatar, Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import React from "react";
import { Input, InputGroup, Button } from 'rsuite';
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { useEffect, useState } from 'react';
import { get, API_URL } from 'utils/API';
import { useDispatch, useSelector } from "react-redux";
import { getLocalToken } from "utils/token";
import { userActions } from "redux/user";
export default function Banner(props) {
  const { banner, avatar, name, data, setData} = props;
  const user = useSelector(state => state.user)
  const dispatch = useDispatch()
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const borderColor = useColorModeValue(
    "white !important",
    "#111C44 !important"
  );

  const [visible, setVisible] = useState(false);

  const handleChange = () => {
      setVisible(!visible);
  };

  const changeAPIKey = async () => {
    var token = getLocalToken()
    var response = await get(`${API_URL}/profile/change-api-key`, token)
    var oldMetadata = user?.metadata
    oldMetadata = {...oldMetadata, api_key: response.new_api_key}
    dispatch(userActions.updateUserMetadata(oldMetadata))
}

  return (
    <Card mb={{ base: "0px", lg: "20px" }} align='center'>
      <Box
        bg={`url(${banner})`}
        bgSize='cover'
        borderRadius='16px'
        h='131px'
        w='100%'
      />
      <Avatar
        mx='auto'
        src={user?.metadata?.picture}
        h='87px'
        w='87px'
        mt='-43px'
        border='4px solid'
        borderColor={borderColor}
      />
      {/* <Flex w='max-content' mx='auto' mt='26px'>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {posts}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Posts
          </Text>
        </Flex>
        <Flex mx='auto' me='60px' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {followers}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Followers
          </Text>
        </Flex>
        <Flex mx='auto' align='center' direction='column'>
          <Text color={textColorPrimary} fontSize='2xl' fontWeight='700'>
            {following}
          </Text>
          <Text color={textColorSecondary} fontSize='sm' fontWeight='400'>
            Following
          </Text>
        </Flex>
      </Flex> */}
      <div className="space-y-4">
        <p><strong>Username:</strong> {user?.metadata?.username}</p>
        <p><strong>Email:</strong> {user?.metadata?.email}</p>
        <p><strong>Balance:</strong> ${parseFloat(user?.metadata?.balance).toFixed(3)}</p>
          <div className="flex items-center justify-center">
            <p><strong>Key: </strong></p>
                        <InputGroup  className=''>

                            <Input type={visible ? 'text' : 'password'} value={user?.metadata?.api_key}/>
                            <InputGroup.Button onClick={handleChange}>
                                {visible ? <EyeIcon /> : <EyeSlashIcon />}
                            </InputGroup.Button>
       
                            <Button className='' onClick={() => changeAPIKey()}>Change</Button>
                        </InputGroup>
      </div>
                    </div>
             

    </Card>
  );
}