import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  } from '@chakra-ui/react'
import {get} from "../../utils/API.js"
import { useState, useEffect, useRef } from 'react'
import { API_URL } from '../../utils/API.js'
import { Heading, Highlight, CircularProgress, Textarea, Box } from '@chakra-ui/react';
import { Button, Modal, Placeholder, Input } from 'rsuite';
export default function TopUp({ user }) {
const [invoices, setInvoices] = useState([]);
const ws = useRef(null);

const [isLoading, setIsLoading] = useState(false);
const [inputText, setInputText] = useState('');
const [showText, setShowText] = useState('');
const [groupsInput, setGroupsInput] = useState('');
const [rowuid, setRowuid] = useState('');


const getInvoices = async () => {
  var token = localStorage.getItem('hearhourshop');
  if (token === null) {
    return;
  }
};

const handleStartClick = async () => {
  try {
    setIsLoading(true);
    let rowuid = 0;
    const cookie = inputText.trim();

    const userInputLines = groupsInput.trim().split('\n'); 

    for (let i = 0; i < userInputLines.length; i++) {
      const userInput = userInputLines[i].trim();
      
      if (!userInput) {
        console.error('Error: User input is empty');
        continue;
      }


      const data = await get(`${API_URL}/profile/getuidfrompost?cookie=${cookie}&idpost=${userInput}`);
      const response = data;

      // Print uid values
      if (Array.isArray(response.uid)) {
        response.uid.forEach(item => {
          setShowText((prevState) => prevState + item.uid + '\n' );
          
        });
      }
      let next = data.next;
      const fb_dtsg = data.fb_dtsg;
      const reactionID = data.reactionID;
      const id = data.id;

      
      while (next) {
        const getNextpost = await get(`${API_URL}/profile/getuidfrompost_next?cookie=${cookie}&id=${id}&reactionID=${reactionID}&fb_dtsg=${fb_dtsg}&next=${next}`);
      
        if (Array.isArray(getNextpost.uid)) {
          getNextpost.uid.forEach(item => {
            setShowText(prevState => prevState + item.uid + '\n');
            rowuid++;
            setRowuid(rowuid.toString());
          });
        }

        if (getNextpost.next === 'None' || getNextpost.uid.length === 0) {
          break; // Break out of the loop if next is 'None' or uid is empty
        }
      
        next = getNextpost.next; // Update the next value for the next iteration
      }

    }
    
  } catch (error) {
    console.error('Error fetching data:', error);
  } finally {
    setIsLoading(false);
  }
};

useEffect(() => {
  const interval = setInterval(() => getInvoices(), 10000);
  return () => {
    clearInterval(interval);
  };
}, []);

return (
  <div className='body-user'>
    <div className='body-tools pb-10'>
      <Heading lineHeight='tall'>
        <Box fontSize='18px'>
          <Highlight
            query='UID'
            styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.400' }}
          >
            Get UID From Post Groups
          </Highlight>{' '}
          {rowuid}
          {isLoading && <CircularProgress isIndeterminate color='green.300' size='30px' />}
        </Box>
      </Heading>

      <div className='col-span-5 md:col-span-1' style={{ backgroundColor: '#ffffff3d', margin: '10px 15px' }}>
      <Textarea className='user-input-cookie scroll4' placeholder='cookie' value={inputText} onChange={e => setInputText(e.target.value)} />
      </div>

      <div className='grid grid-cols-2'>
        <div className='col-span-5 md:col-span-1' placeholder='cookie'>
          <div className='col-span-5 lg:col-span-1' style={{ position: 'relative' }}>
            <div className='textarea' style={{ backgroundColor: '#ffffff3d', margin: '10px 15px' }}>
            <Textarea className='user-input scroll4' placeholder='List ID POST' value={groupsInput} onChange={e => setGroupsInput(e.target.value)} />

            </div>
            <Button className='button-copy' onClick={handleStartClick}>
              START
            </Button>
              </div>
              </div>
              
              <div className="col-span-5 md:col-span-1">
              <div className="col-span-1 lg:col-span-1" style={{ position: 'relative'}}>
              <div className="textarea" style={{backgroundColor:'#ffffff3d', margin:'10px 15px'}}>
                  <Textarea className="user-input scroll4" placeholder="result" value={showText} onChange={e => setShowText(e.target.value)} />
              </div>
              </div>
              </div>
          </div>
          </div>
      </div>

  )
}