import { Table, Thead, Tbody, Tr, Th, Td } from '@chakra-ui/react';
import { useState, useEffect } from 'react';
import Country from 'components/Country/Country';
import { Button } from 'rsuite';
import { useSelector } from 'react-redux';

export default function PatternTable({columnDef, columnData}) {

    return (
        <div className=''>
            <Table
              className='table-fixed table-home shadow-2xl'
              variant='simple'>
              <Thead>
            
                {/* <Tr> */}
                    {/* <Th className='truncate hover:text-clip md:table-cell w-[210px] min-w-[210px] max-w-[210px]'></Th> */}
                  {/*<Th className=''>Month</Th>   hidden lg:table-cell truncate w-[300px] min-w-[300px] max-w-[300px] */}
                  {/** <Th className=''>Sales</Th>  hidden lg:table-cell  */}
                  {/* <Th className='hidden md:table-cell'>Price/Account</Th> */}
                  {/* <Th>In Stock</Th> */}
                  {/* <Th></Th> */}
                {/* </Tr> */}
                <Tr>
                {columnDef && columnDef?.map((each , index) => {
                    return (<Th key={index}>{each.header}</Th>)
                })}
                </Tr>
          


              </Thead>
              <Tbody>
              {/* <Tr className="bg-blue-300" > */}
              {columnData && columnData.map((each, index) => (
                    <Tr key={index}>
                        {columnDef.map((dataDef, dataIndex) => (
                        <Td key={dataIndex}  style={dataDef?.style}>{dataDef?.prefix} {each[dataDef.accessor]} {dataDef?.suffix}</Td>
                        ))}
              </Tr>
))}


                           {/* columnData?.map((each , index ) => {

                    return (
                        <div></div>
                    )

    
                }) */}
              {/* </Tr> */}
             
                {/* {stocks.stocksByCategory?.get(category).slice(0, shouldShow(category) ? stocks.stocksByCategory.get(category).length : 5).map((each) => (
                  <Tr key={each.title}>
                    <Td className='items-start'>
                      <div className='flex items-center'>
                        <img
                          src={each.icon ? each.icon : PlaceHolder}
                          alt={each.name}
                          className='w-6 h-6 mr-2'
                        />
                        {each.name}
                      </div>
                    </Td>
                    <Td className='items-start hidden lg:table-cell truncate w-[300px] min-w-[300px] max-w-[300px]'>
                      <div className='flex items-center'>
                        <div className='flex items-center'>
                          <Country country={each.country} />
                          <div className='truncate'>{each.detail}</div>
                        </div>
                      </div>
                    </Td>
                    <Td className='hidden lg:table-cell'>
                      {each.date_created}
                    </Td>
                    <Td className='hidden md:table-cell'>
                      <dd className='chakra-stat__help-text css-gbp32c'>
                        ${parseFloat(each.price).toFixed(3)}
                      </dd>
                    </Td>
                    <Td>{each.stock > 0 ? each.stock : <img src={SoldOut} alt="Sold Out" width={30} height={30}/>}</Td>
                    <Td>
                      <Button color='green' appearance='primary' onClick={() => handleOpen(each)} disabled={shouldNotBeAbleToBuy(each.stock)}>
                        <strong>Buy</strong>
                      </Button>
                    </Td>
                  </Tr>
                ))} */}
              </Tbody>
            </Table>
        </div>
    )
}