import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
} from '@chakra-ui/react'
import HearHour from "../../images/hearhour.png"
import { Button, Modal, Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useRef, useState, useReducer } from 'react';
import DashboardIcon from "../../images/dashboard-icon.png"
import { API_URL, WS_URL, get } from '../../utils/API';
import Skeleton from 'react-loading-skeleton'
import Country from '../../components/Country/Country.jsx'
import { Image } from '@chakra-ui/react'
import CopyLink from "../../images/copy.png"
import {
Breadcrumb,
BreadcrumbItem,
BreadcrumbLink,
} from '@chakra-ui/react'
import LoginButton from 'components/Auth0/Login'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from '../../utils/toast'
import "./Main.css"
import { useAuth0 } from "@auth0/auth0-react";
import { useSelector } from 'react-redux'
import { getLocalToken } from 'utils/token'
import useBuy from 'hooks/useFetcher'
import useFetcher from 'hooks/useFetcher'
import CategoryTable from './CategoryTable';
import {Helmet} from "react-helmet";
import axios from 'axios';
import { v4 as uuid } from 'uuid';
import { motion } from 'framer-motion';
import Loading from "../../images/loading.svg"
const data = []

const getLoadingSkeletons = () => {
return (
  <Tr>
        <Td className='items-center'><Skeleton/></Td>
        <Td className='hidden lg:table-cell'><Skeleton/></Td>
        <Td className='hidden md:table-cell'><Skeleton/></Td>
        <Td className='hidden lg:table-cell'><Skeleton/></Td>
        <Td className='hidden md:table-cell'><Skeleton/></Td>
        <Td><Skeleton/></Td>
        <Td><Skeleton/></Td>
  </Tr>
)
}

const skeletons = []
for (var i = 0; i < 10; i++) {
skeletons.push(getLoadingSkeletons())
}

function showMoreReducer(state, action) {
  switch (action.type) {
    case "SHOW_ALL_MAILSTOCKS":
      return {...state, showAllMailStocks: true}
    case "SHOW_LESS_MAILSTOCKS":
      return {...state, showAllMailStocks: false}
    case "SHOW_ALL_STOCKS":
      return {...state, showAllStocks: true}
    case "SHOW_LESS_STOCKS":
      return {...state, showAllStocks: false}
  }
}

export default function Main(props) {
  const [searchParams, setSearchParams] = useSearchParams();
  const {getUserProfile} = props
  const user = useSelector(state => state.user)
  const navigate = useNavigate()
  const [currentProduct, setCurrentProduct] = useState(null)
  const [qty, setQty] = useState(0)
  const [total, setTotal] = useState(0.0)
  const marqueeRef = useRef(null);
  const [stocks, setStocks] = useState(null)
  const [mailstocks, setmailStocks] = useState(null)
  const [fetching, setFetching] = useState(true)
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState('static');
  const [announcements, setAnnouncements] = useState([])
  const [currentAnnouncementIndex, setCurrentAnnouncementIndex] = useState(0);
  const ws = useRef(null)
  const [quantity, setQuantity] = useState('0');
  const isBuyButtonDisabled = isNaN(parseInt(quantity)) || isNaN(parseFloat(user?.metadata?.balance)) || parseInt(quantity) === 0;
  const { isAuthenticated, loginWithRedirect } = useAuth0()
  const [showMores, showMoreDispatch] = useReducer(showMoreReducer, { 
      showAllMailStocks: false,
      showAllStocks: false
  });
  const parent = useRef(null)
  const {loading: purchasing, FETCHER} = useBuy()
  const {loading: fetchingStocks, FETCHER: STOCKER_FETCHER} = useFetcher()


  const [streamingData, setStreamingData] = useState('');
  const [streamModalOpen, setStreamModalOpen] = useState(false);
  // const openStreamModal = async (transactionId,  quantity) => {
  //   setStreamModalOpen(true);
  //   // const eventSource = new EventSource(`${API_URL}/profile/transaction-history/stream/a7939b3b`);
  //   var token = getLocalToken()
  //   // fetch(`${API_URL}/profile/transaction-history/stream/${transactionId}?quantity=${quantity ? quantity : 100}&token=${token}`)
  //   // // Retrieve its body as ReadableStream
  //   // .then((response) => {
  //   //   const reader = response.body.getReader();
  //   //   // read() returns a promise that resolves when a value has been received
  //   //   reader.read().then(function pump({ done, value }) {

  //   //     if (done) {
  //   //       // Do something with last chunk of data then exit reader
  //   //       return;
  //   //     }
  //   //     // Otherwise do something here to process current chunk
  //   //     const chunk = new TextDecoder().decode(value);
  //   //     console.log(chunk)
  //   //     setStreamingData(chunk)
  //   //     // Read some more, and call this function again
  //   //     return reader.read().then(pump);
  //   //   });
  //   // })
  //   // .catch((err) => console.error(err));
  // };

  const processTransactionDetails = (transactionDetails) => {
    var details = ""
    transactionDetails?.map(each => {
      details += each.detail + "\n"
    })
    details = details.substring(0, details.length - 1)
    return details
  }

  const openStreamModal = (transactionId, quantity) => {
    setStreamModalOpen(true);
  
    let previousResponse = null; // Variable to store the previous response
  
    const fetchData = () => {
      const token = getLocalToken();
  
      fetch(`${API_URL}/profile/transaction-history/view/${transactionId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then((response) => response.json())
        .then((data) => {
          // Process the received data as needed
          var transactionDetails = processTransactionDetails(transactionDetails)
  
          // Compare the current response with the previous response
          if (transactionDetails === previousResponse) {
            // If the responses are identical, stop fetching

            return;
          }

          setStreamingData(transactionDetails);
          // Update the previous response
          previousResponse = data;
          if (!streamModalOpen) {return}
  
          // Continue fetching data
          fetchData();
        })
        .catch((error) => {
          // Handle any error that occurs during the fetch request
          console.error(error);
        });
    };
  
    // Start fetching data initially
    fetchData();
  };
  
  const closeStreamModal = () => {
    setStreamModalOpen(false);
    setStreamingData('');
  };
  

  // useEffect(() => {
  //   const eventSource = new EventSource(`${API_URL}/profile/transaction-history/stream/a7939b3b`);

  //   eventSource.onmessage = (event) => {
  //     setStreamingData((prevData) => prevData + event.data + '\n');
  //   };

  //   return () => {
  //     // Clean up the event source connection when the component unmounts
  //     eventSource.close();
  //   };
  // }, []);

  const redirectToAddFund = () => {
    if (user?.metadata?.role !== "USER") {
      /* Toast here */
      return
    }
    localStorage.setItem("redirector", "topUp")
    navigate("/dashboard")
  }

  const handleOpen = (product) => {
    if (product?.hasOwnProperty("MailCode")) {
      setCurrentProduct(product)
      setOpen(true);
      return
    } 
    setSearchParams({...searchParams, product_id: product.id, product_name: product.name, category: product.category})
    setCurrentProduct(product)
    setOpen(true);
  }
  const handleClose = () => {
      setSearchParams({...searchParams})
      setTotal(0.0)
      setOpen(false);
      setQuantity(0);
  }

  const goToDashboard = () => {
    if (user.metadata?.role === null  || user.metadata?.role === undefined) {return}
    if (["ADMIN", "SELLER"].includes(user.metadata.role)) {
      localStorage.setItem("redirector", "Main")
      navigate("/admin")
      return
    }
    navigate("/dashboard")
  }

  const processStocksByCategory = (stocks) => {
    var stocksByCategory = new Map()
    var categories = []
    stocks?.forEach(each => {
      if (stocksByCategory.has(each.category)) {
        var stocksFromACategory = stocksByCategory.get(each.category)
        stocksByCategory.set(each.category, [...stocksFromACategory, each])
      } else {
        stocksByCategory.set(each.category, [each])
        categories.push(each.category)
      }
    })
    setStocks({
      categories: categories,
      stocksByCategory: stocksByCategory
    })
    // console.log(stocksByCategory)
  }

  async function getmailStocks() {
    // setFetching(true)
    var responsemail = await get(`https://api.hotmailbox.me/mail/currentstock`)
    setmailStocks(responsemail.Data)
  }

  

  const purchaseAccounts = async () => {
    if (currentProduct.hasOwnProperty("MailCode")) {
      purchaseHotmailAccounts()
      return
    } 
    var token = localStorage.getItem("hearhourshop")
    if (token === "" || token === undefined) {
      return
    }
    // var response = await post("https://mmoshop.hearhour.repl.co/store/buy", {token: token, quantity: qty, product_id: productId})

    if (currentProduct.stock === 0) {
      toast.error('Product is out of stock!', TOAST_OPTIONS);
      handleClose()
      return
    }
    if ((currentProduct.stock - qty) < 0) {
      toast.error('We do not have enough stock for your purchase', TOAST_OPTIONS);
      handleClose()
      return
    }

    var response = await FETCHER(`${API_URL}/store/buy`, "POST", {
      token: getLocalToken(),
      body: {quantity: qty, product_id: currentProduct?.id}
    })

    if (response.code !== 200) {
      toast.error(response.message, TOAST_OPTIONS);
      handleClose()
      return
    }
    toast.success(response.message, TOAST_OPTIONS);
    ws.current.send("update-stock")  
    handleClose()
    
    // openStreamModal(response.transaction_id, response.quantity)
  }

  const purchaseHotmailAccounts = async () => {
    var token = localStorage.getItem("hearhourshop")
    if (token === "" || token === undefined) {
      return
    }
    if (["HOTMAIL.TRUSTED", "OUTLOOK.TRUSTED"].includes(currentProduct.MailCode) && qty < 10) {
        toast.error('You need to buy at least 10!', TOAST_OPTIONS);
        handleClose()
        return
    }
    // var response = await post("https://mmoshop.hearhour.repl.co/store/buy", {token: token, quantity: qty, product_id: productId})
    if (currentProduct.Instock === 0) {
      toast.error('Product is out of stock!', TOAST_OPTIONS);
      handleClose()
      return
    }
    if ((currentProduct.Instock - qty) < 0) {
      toast.error('We do not have enough stock for your purchase', TOAST_OPTIONS);
      handleClose()
      return
    }

    var response = await FETCHER(`${API_URL}/store/buy/hotmail`, "POST", {
      token: getLocalToken(),
      body: {quantity: qty, mailcode: currentProduct?.MailCode}
    })
    if (response.code !== 200) {
      toast.error(response.message, TOAST_OPTIONS);
      handleClose()
      return
    }
    toast.success(response.message, TOAST_OPTIONS);
    ws.current.send("update-stock")  
    handleClose()
    
    // openStreamModal(response.transaction_id, response.quantity)
  }

  const updateTotal = (e, price) => {
    if (isNaN(parseInt(e)) || e === "" || e < 0) {
      setTotal(0.0)
      return
    }
    setQty(parseInt(e))
    setTotal(parseFloat(price * parseInt(e)).toFixed(3))
    setQuantity(parseInt(e));
  }

  useEffect(() => {


  }, [stocks])

  async function getStocks() {
    try {
      var response = await STOCKER_FETCHER(`${API_URL}/store/get-stocks`, "GET")
      if (!response) {return}
      if (response.code !== 200) {return}
      processStocksByCategory(response.stocks)
      setAnnouncements(response.announcements)
    }  catch {return}
  }

  const copyShareLink = async (each) => {
    var url = `http://api.mmoshop.me/store/redirector?product_id=${each.id}&category=`+ encodeURIComponent(each.category) + "&sid=" + uuid().slice(0, 5)
    navigator.clipboard.writeText(url.toString())
    /**Toast: Copied to clipboard */
    toast.success("Copied to clipboard", TOAST_OPTIONS)
    try {
      var response = await fetch(url) 
    } catch {
      return
    }


  }

  const autoOpenModalFromLink = () => {
    if (searchParams?.get("product_id") && searchParams?.get("category") &&stocks) {
      var product = stocks?.stocksByCategory.get(searchParams?.get("category"))?.find((each) => each.id === parseInt(searchParams.get("product_id")))
      if (product) {
        handleOpen(product)
        return
      }
      handleClose()
    }
  }

  useEffect(() => {
   autoOpenModalFromLink()
  }, [searchParams, stocks])

  useEffect(() => {
    const updateAnnouncementIndex = () => {
      setCurrentAnnouncementIndex((prevIndex) => (prevIndex + 1) % announcements.length);
    };

    const interval = setInterval(updateAnnouncementIndex, 25000);

    return () => {
      clearInterval(interval);
    };
  }, [announcements]);
  

  useEffect(() => {
    getStocks()
    getmailStocks()
    autoOpenModalFromLink()
    try {
      getUserProfile()
    } catch {}
    ws.current = new WebSocket(`${WS_URL}/ws`)

    ws.current.onmessage = (event) => {
      if (event.data === "update-stock") {
        getUserProfile()
        getStocks()
        getmailStocks()
      }
    }

    const interval = setInterval(() => {
      getmailStocks()
    }, 100 * 200)
    
    return () => {
      if (ws.current) {
        ws.current.close();
      }
      clearInterval(interval)
    };

  }, [])

  return (
      <div className="header-bg main-background scroll-main default-font">
      <div className='items-start' style={{ position: 'relative', justifyContent: "center", alignItems: "center"}}>

<Helmet>
<script>{`
(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3559370,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}

</script>
<meta></meta>
</Helmet>
      <div className='grid grid-cols-12'>
        <div className='col-span-6 p-1'>
          <div className="cursor-pointer w-40 md:w-96" onClick={() => navigate("/")}>
            <img src={HearHour} alt="" width={250} />
          </div>
        </div>
        <div className='col-span-6 p-1'>
        {
          isAuthenticated ?
            <div className='space-x-3' style={{ position: "absolute", top: "1em", right: "1em" }}>
              <Button onClick={() => goToDashboard()}>
                <img src={DashboardIcon} alt="" className='me-2 w-6 h-6'/>
                <p>Dashboard</p>
              </Button>
            </div>
          
          :
          <div className='space-x-3' style={{ position: "absolute", top: "1em", right: "1em" }}>
            <LoginButton/>
         
            {/* <Button onClick={() => navigate("/login")}>Login</Button>
            <Button onClick={() => navigate("/register")}>Create an account</Button> */}
          </div>
        }
        </div>
      </div>
     

      </div>

      {/* <div className='p-1 space-x-3' style={{ position: "absolute", top: "5em", right: "1em" }}> */}
      <div className='p-1 space-x-3' style={{ display:'flex', flexDirection:'row-reverse', marginRight:'15px' }}>
        <div className='text-gray-50 flex justify-center items-center py-2'>
        <Breadcrumb separator='|' className='main'>
            <BreadcrumbItem>
            <BreadcrumbLink onClick={() => navigate('/tools')} >Facebook Tools</BreadcrumbLink>
            </BreadcrumbItem>

            <BreadcrumbItem>
            <BreadcrumbLink  onClick={() => navigate('/mailcode')}>Mail Code</BreadcrumbLink>
            </BreadcrumbItem>


            <BreadcrumbItem>
            <BreadcrumbLink href='https://docs.mmoshop.me/' target="_blank">APIs Document</BreadcrumbLink>
            </BreadcrumbItem>
        </Breadcrumb>
        </div>
        </div>


      <div className="">
        <div className="col-span-3">

      


        <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}}>
          <div className='' style={{ borderRadius: 15, paddingBottom:'10vh'}} ref={parent}>
          <TableContainer style={{ width: "100%" }}>

        {
         Array.isArray(announcements)  && announcements?.length > 0 &&         
          <div className='banner-alert' style={{
          overflow: 'hidden', // Hides the scrollbar when content doesn't overflow the container
          color: 'white',
          // maxWidth: '100%',
        }}>
          <div className='marquee-container'>
            <div className='marquee'>
            {announcements && announcements.length > 0 && announcements[currentAnnouncementIndex].announcement}
            </div>
          </div>
        </div>
        }



        <div  className='row-banner grid grid-cols-2'>
        <div className='banner col-span-5'>
          <div className='banner-text'>
          <strong className='text-sm'>BALANCE :<strong  className='text-price-show'>
          {isNaN(parseFloat(user?.metadata?.balance).toFixed(3))
            ? <> <strong  className='text-price-show'> $0.000 </strong></>
            : `$${parseFloat(user?.metadata?.balance).toFixed(3)}`
          }
          </strong></strong>  
          </div>

         
          <div className='baner-button'>
            <Button className='button-colum' color="green" appearance="primary" onClick={() => redirectToAddFund()}>Add fund</Button>
            <a href="https://youtu.be/GCYHTNmJ3Ok" target="_blank" >
            <Button className='button-contact' color="green" appearance="primary" > <span className="telegram-icon"></span>របៀបដាក់លុយ​</Button>
            </a>
        </div>
  </div>


        </div>

          <div className="grid grid-cols-1 items-center justify-center"
           
          >
          {fetchingStocks ? <div className='w-full flex justify-center items-center'><Image src={Loading} alt="" width={300} height={300}/></div> :
            <div>
            <motion.div
              initial={{opacity: 0, y: "25%"}}
              animate={{opacity: 1, y: "0%"}}
              transition={{duration: 0.75, delay: 0.25}}
            >
              <CategoryTable stocks={stocks} handleOpen={handleOpen}/>
            </motion.div>
            <div className="col-span-1 lg:col-span-1">
                  <Table className='table-fixed col-span-1 lg:col-span-1 table-home max-w-[1150px]' variant='simple' >
                    <Thead >
                      <Tr >
                        <Th className='truncate hover:text-clip md:table-cell w-[210px] min-w-[210px] max-w-[210px]'>Mail</Th>
                        <Th className='hidden lg:table-cell truncate w-[550px] min-w-[550px] max-w-[550px]'>
                    Detail
                  </Th>
                       
                        <Th className='hidden md:table-cell'>Price/Account</Th>
                        <Th>In Stock</Th>
                        <Th></Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                    {mailstocks ? (
                      mailstocks.slice(0, showMores?.showAllMailStocks ? mailstocks.length : 5).map((each) => (

                      <Tr key={each.title}>
                          <Td className='items-start'>
                            <div className="flex items-center">
                            <div className="flex items-center">
                            <Image src={each.Icon}  boxSize='20px' className="w-6 h-6 mr-2" />
                            <div className="truncate">{each.GroupName}</div>
                          </div>
                          </div>
                          </Td>
                          <Td className='hidden md:table-cell truncate w-[300px] min-w-[300px] max-w-[300px]'>{each.MailCode} | {each.LiveTime}​</Td>
                          <Td className='hidden md:table-cell'><dd className="chakra-stat__help-text css-gbp32c">${(each.PriceUsd + 0.000778).toFixed(6)}</dd></Td>
                          <Td>{each.Instock}</Td>
                          <Td>
                            <Button color="green" appearance="primary" onClick={() => handleOpen(each)}>
                              <strong>Buy</strong>
                            </Button>
                          </Td>
                        </Tr>

                      ))) : (
                        skeletons.map((each) => each)
                      )}
                    </Tbody>
                  </Table>
              </div>
              <div className="">
              {!showMores?.showAllMailStocks && mailstocks && Array.isArray(mailstocks) && mailstocks.length > 2 ? 
                <Button className='button-more' onClick={() => showMoreDispatch({type: "SHOW_ALL_MAILSTOCKS"})}>
                  View all
                </Button> : <Button className='button-more' onClick={() => showMoreDispatch({type: "SHOW_LESS_MAILSTOCKS"})}><img src="https://img.icons8.com/?size=512&id=h80UkgzZcDVa&format=png" width={30} height={30}/></Button>
              }
              </div>
              </div>
          }
        
     
   
          </div>

</TableContainer>

          </div>
          </div>

        </div>
        <ToastContainer
          position="top-right"
          autoClose={3500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
      />

    <Modal
      size="md"
      backdrop="static"
      keyboard={false}
      open={streamModalOpen}
      onClose={closeStreamModal}
      
      style={{ paddingTop: '80px'}}
    >
      <Modal.Header>
        <h5>Account Details</h5>
      </Modal.Header>

      <Modal.Body style={{height: "50vh"}}>
        <pre>{streamingData}</pre>
      </Modal.Body>

      <Modal.Footer>
        <Button onClick={closeStreamModal}>Close</Button>
      </Modal.Footer>
    </Modal>
      
 
      <Modal size='xs' backdrop={backdrop} keyboard={false} open={open} onClose={handleClose} style={{paddingTop:'80px', }}>
      <Modal.Header>
        <h5>Purchase</h5>
      </Modal.Header>

      <Modal.Body >
        <div className="popup-buy">
        <p>Quantity: </p>
        <Input className="user-input-rg" type="number"  placeholder="Enter quantity to buy" onChange={(e) => updateTotal(e, currentProduct?.price ? currentProduct?.price : currentProduct?.PriceUsd+ 0.000778)}/>
        </div>

        <div className="popup-buy">
        <p>Price:</p>
        <p>$ {(currentProduct?.PriceUsd ? currentProduct.PriceUsd + 0.000778 : currentProduct?.price)}</p>
        </div>

        <div className="popup-buy">
        <p>Total:</p>
        <p>$ {total}</p>

        </div>


        <p className='font-bold'>


          {isNaN(parseFloat(user?.metadata?.balance))
            ? <> Your current balance : <span className="red-text cursor-pointer" onClick={() => loginWithRedirect()}>Please Login</span> </>
            : `Your current balance : $ ${parseFloat(user?.metadata?.balance).toFixed(3)}`
          }
        </p>

          {!isNaN(parseFloat(user?.metadata?.balance)) && (
            <p className='font-bold'>
              Balance after buying: $ {parseFloat(user?.metadata?.balance - total).toFixed(3)}
            </p>
          )}

          {currentProduct && 
              <p className='font-bold'>
              Detail: {currentProduct?.detail}
              </p>
            }

            <div onClick={() => copyShareLink(currentProduct)} className='flex'> 
              <p className='font-bold'>Share Link: </p>
              <img src={CopyLink} alt="share-link" width={30} height={30} className='hover:scale-110 cursor-pointer '/>
            </div>

      </Modal.Body>
      <Modal.Footer>
        {!purchasing ?  <Button className="button-buy"  onClick={() => purchaseAccounts()}  disabled={isBuyButtonDisabled}>BUY NOW!</Button> : <Button loading={purchasing} className="button-buy">Buying</Button>}
      </Modal.Footer>
    </Modal>

      
          </div>
        </div>
    );
}