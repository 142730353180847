import axios from "axios"
import { useEffect, useState } from "react"
import PatternTable from "views/admin/default/Table"
import { Button } from "rsuite"
import Show from "./icons/show.png"
import Hide from "./icons/hide.png"
import Add from "./icons/add.png"
import {Input} from "rsuite"
import Select from "react-select";
import { API_URL } from "utils/API"
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast"

const announcementColDef = [
    {
        header: "ANNOUNCEMENT",
        accessor: "announcement"
    },
    {
        header: "TYPE",
        accessor: "type"
    },
    {
        header: "HIDDEN",
        accessor: "hidden"
    },
    {
        header: "DELETE",
        accessor: "delete"
    }
]

const announcementTypes = ["INFO", "PROMOTION", "DISCOUNT", "EMERGENCY"]

const options = announcementTypes.map((each) => {
        return {label: each, value: each}
})
const newAnnouncementObject = {
        announcement: "",
        type: ""
    }

export default function Announcement() {
    const [newAnnouncement, setNewAnnouncement] = useState(newAnnouncementObject)
    const [announcements, setAnnouncements] = useState([])

    const deleteAnnouncement = async (announcement) => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.delete(`${API_URL}/admin/announcement/delete`,
        {
            data: {id: announcement.id, announcement: announcement.announcement, type: announcement.type},
            headers: {"Authorization": `Bearer ${token}`}
        })

        if (response?.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }

        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchAnnouncements()
    }

    const fetchAnnouncements = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.get(`${API_URL}/admin/announcement/get`, {headers: {"Authorization": `Bearer ${token}`}})

        if (response?.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }
        var announcements = response?.data?.announcements
        announcements?.forEach((each) => {
            each["hidden"] = each["hidden"].toString()
            each["delete"] = <Button onClick={() => deleteAnnouncement(each)} appearance="primary" color="red">Delete</Button>
        }) 
        setAnnouncements(announcements)
    }

    const addNewAnnouncement = async () => {
        var token = localStorage.getItem("hearhourshop")
        if (newAnnouncement?.announcement === "" || newAnnouncement?.type === "") {
            toast.error("Both announcement and type must be completed", TOAST_OPTIONS)
            return
        }

        var response = await axios.post(`${API_URL}/admin/announcement/add`, newAnnouncement, {headers: {"Authorization": `Bearer ${token}`}})
        
        if (response?.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }

        setNewAnnouncement(newAnnouncementObject)
        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchAnnouncements()
    }

    const showAllAnnouncements = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.get(`${API_URL}/admin/announcement/show-all`, {headers: {"Authorization": `Bearer ${token}`}})

        if (response?.status !== 200) {
            console.log(response)
            return
        }


        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchAnnouncements()
    }

    const hideAllAnnouncements = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.get(`${API_URL}/admin/announcement/hide-all`, {headers: {"Authorization": `Bearer ${token}`}})

        if (response?.status !== 200) {
            console.log(response)
            return
        }

        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchAnnouncements()
    }

    useEffect(() => {
        fetchAnnouncements()
    }, [])

    return (
        <div className="mt-5 space-y-5">
            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
              />
            <div className="flex space-x-4">
                <Button onClick={() => addNewAnnouncement()}>
                    <img src={Add} alt="" width={30} height={30}/>
                    Add
                </Button>
                <Button onClick={() => showAllAnnouncements()}>
                    <img src={Show} alt="" width={30} height={30}/>
                    Show All
                </Button>
                <Button onClick={() => hideAllAnnouncements()}>
                    <img src={Hide} alt="" width={30} height={30}/>
                    Hide All
                </Button>
            </div>

            <div className="grid grid-cols-12 items-center">
                <div className="col-span-6 flex">
                    <Input value={newAnnouncement.announcement} onChange={e => setNewAnnouncement({...newAnnouncement, announcement: e})}/>
                </div>
                <div className="col-span-2">
                   <Select options={options} onChange={(values) => setNewAnnouncement({...newAnnouncement, type: values.value})} style={{width: 100}}/>
                </div>
            </div>

            <PatternTable columnData={announcements && announcements} columnDef={announcementColDef}/>
        </div>
    )
}