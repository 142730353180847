import { useEffect, useState, useCallback } from "react";
import { Button, Input } from "rsuite";
import { API_URL, get } from "utils/API";
import { Radio, RadioGroup, Stack as ChakraStack} from '@chakra-ui/react'
import { getLocalToken } from "utils/token";
import Pagination from "react-js-pagination";

import {
  Card,
  CardBody,
  Avatar,
  CardHeader,
  IconButton,
  Text,
  Image,
  CardFooter,
} from "@chakra-ui/react";
import { useDisclosure } from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  ModalBody,
} from "@chakra-ui/react";

const ProfileModal = ({ open, handleCloseModal, user, fetchUsers }) => {
    const [edit, setEdit] = useState({
        topup: 0.0,
        new_balance: user.balance,
        operation: "",
    })
    const [selection, setSelection] = useState('add')

    const updateBalance = async () => {
      if (isNaN(edit.topup)) {
        return
      }
      var response = await get(API_URL + `/admin/add-balance?username=${user.username}&topup=${edit.topup}&operation=${selection}`, getLocalToken())
      if (response.code !== 200) {
        //toast here 
      }
      fetchUsers()
    }

    const parseTopup = (amount) => {
      // console.log(amount)
      if (isNaN(amount) || amount === undefined || amount === null) {
        return
      }
      setEdit({...edit, topup: parseFloat(amount)})
    }

    // useEffect(() => {
    //   setEdit({...edit, new_balance: parseFloat(edit.topup) + parseFloat(edit.new_balance)})
    // }, [edit.topup])

    // useEffect(() => console.log(edit)) 

  return (
    <Modal isOpen={open} onClose={handleCloseModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Add Balance</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
        <Avatar name={user?.username} src={user?.picture} />
        
          <p>
            <strong>Username:</strong> {user?.username}
          </p>
          <p>
            <strong>Balance:</strong> ${user?.balance}
          </p>
          <p>
            <RadioGroup onChange={setSelection} value={selection}>
              <ChakraStack spacing={5} direction={"row"}>
                <Radio value='add'>Add</Radio>
                <Radio value='substract'>Substract</Radio>
              </ChakraStack>
            </RadioGroup>
          </p>
       
          <p>
            <strong>Add Balance:</strong> <Input value={edit.topup} onChange={(value) => parseTopup(value)} type="number" />
          </p>
          {/* Add your form fields or content here */}
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={() => updateBalance()}>
            Save
          </Button>
          <Button onClick={handleCloseModal}>Cancel</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default function AddBalance() {
  const [search, setSearch] = useState("");
  const [users, setUsers] = useState([]);
  const [isOpen, setIsOpen] = useState([]);
  const { onClose } = useDisclosure();
  const [pagination, setPagination] = useState({
    active: 1,
    total_rows: 1
  })

  const fetchUsers = useCallback(async () => {
    const response = await get(
      `${API_URL}/admin/get-users?search=${search}&page=${pagination.active}`,
      getLocalToken()
    );
    if (response.code !== 200) {
      return;
    }
    setPagination({...pagination, total_rows: response.total_rows})
    setUsers(response.data);
    setIsOpen(Array(response.data.length).fill(false)); // Initialize isOpen array
  }, [search, pagination.active]);

  const fetchUsersFirstPage = async () => {
    const response = await get(
      `${API_URL}/admin/get-users?search=${search}&page=1`,
      getLocalToken()
    );
    if (response.code !== 200) {
      return;
    }
    setPagination({...pagination, total_rows: response.total_rows, active: 1})
    setUsers(response.data);
    setIsOpen(Array(response.data.length).fill(false)); // Initialize isOpen array
  }

  const updateBalance = (e, user) => {
    setUsers((prevUsers) =>
      prevUsers.map((eachUser) => {
        if (eachUser.id === user.id) {
          eachUser.balance = e;
        }
        return eachUser;
      })
    );
  };

  const handleOpenModal = (index) => {
    setIsOpen((prevIsOpen) =>
      prevIsOpen.map((value, i) => (i === index ? true : value))
    );
  };

  const handleCloseModal = (index) => {
    setIsOpen((prevIsOpen) =>
      prevIsOpen.map((value, i) => (i === index ? false : value))
    );
    onClose();
  };
  useEffect(() => {fetchUsers()}, [pagination.active])


  return (
    <div className="pt-20 space-y-4">

      <div className="grid grid-cols-8">
        <div className="col-span-4 lg:col-span-3 xl:col-span-2">
          <p className="font-bold">
            Search for users (ID, AUTH0_ID, Username, Email):
          </p>
        </div>
        <div className="col-span-4 lg:col-span-5 xl:col-span-4">
          <Input onChange={(e) => setSearch(e)} value={search} onKeyDown={(e) => {
            if (e.key === 'Enter') {
              fetchUsersFirstPage()
            }
          }}/>
        </div>
      </div>
      <div className="">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:grid-cols-6 gap-4 ">
          {users?.map((user, index) => (
            <div className="col-span-1 w-full" key={user.id}>
              <Card maxW="md" className="h-full">
                <CardHeader>
                  <div className="w-full text-center">
                    <Avatar name={user?.username} src={user?.picture} />
                  </div>
              
                  <IconButton
                    variant="ghost"
                    colorScheme="gray"
                    aria-label="See menu"
                    icon={null}
                  />
                </CardHeader>
                <CardBody>
                  <p>
                    <strong>Username:</strong> {user?.username}
                  </p>
                  <p>
                    <strong>Balance:</strong> ${user?.balance}
                  </p>
                </CardBody>
                <CardFooter
                  justify="space-between"
                  flexWrap="wrap"
                  sx={{
                    "& > button": {
                      minW: "136px",
                    },
                  }}
                >
                  <div style={{ width: "100%" }}>
                    {/* Add any additional content here */}
                  </div>
                  <Button
                    onClick={() => handleOpenModal(index)}
                    className="button-buy"
                  >
                    Edit
                  </Button>
                </CardFooter>
              </Card>
              <ProfileModal
                open={isOpen[index]}
                handleCloseModal={() => handleCloseModal(index)}
                user={user}
                fetchUsers={fetchUsers}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="pagination p-5">
        <Pagination
            activePage={pagination.active}
            itemsCountPerPage={20}
            totalItemsCount={pagination.total_rows}
            pageRangeDisplayed={10}
            onChange={(e) => setPagination({...pagination, active: e})}
          />
      </div>
    </div>
  );
}
