import { useEffect, useState } from "react"
import axios from "axios"
import { API_URL } from "utils/API"
import { getLocalToken } from "utils/token"
import PayoutTable from "./PayoutTable"
import { Button } from "rsuite"
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast";

const adminPayoutColDef = [
    {
      header: "ID",
      accessor: "SellerPayout.id",
    },
    {
      header: "USERNAME",
      accessor: "SellerPayout.username",
    },
    {
      header: "SUBMITTED DATE",
      accessor: "SellerPayout.submitted_date",
    },
    {
      header: "RESPONDED DATE",
      accessor: "SellerPayout.responded_date",
    },
    {
      header: "AMOUNT",
      accessor: "SellerPayout.amount",
    },
    {
      header: "PAYMENT TYPE",
      accessor: "SellerPaymentMethod.payment_type",
    },
    {
      header: "PAYMENT ADDRESS",
      accessor: "SellerPaymentMethod.payment_address",
    },
    {
        header: "STATUS",
        accessor: "SellerPayout.status"
    },
    {
        header: "APPROVE",
        accessor: "SellerPayout.approve"
    },
    {
        header: "DENY",
        accessor: "SellerPayout.deny"
    }
  ];

export default function ApprovePayout() {
    const [payouts, setPayouts] = useState([])

    const approvePayout = async (payout) => {
        var token = getLocalToken()
        var response = await axios.get(`${API_URL}/admin/payout/approve/${payout.SellerPayout.id}`, {headers: {"Authorization": `Bearer ${token}`}})
    
        if (response?.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }
        
        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchPayouts()
    }

    const denyPayout = async (payout) => {
        var token = getLocalToken()
        var response = await axios.get(`${API_URL}/admin/payout/deny/${payout.SellerPayout.id}`, {headers: {"Authorization": `Bearer ${token}`}})
    
        if (response?.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }

        toast.success(response?.data?.message, TOAST_OPTIONS)
        fetchPayouts()
    }

    const fetchPayouts = async () => {
        var token = getLocalToken()
        try {
            var response = await axios.get(`${API_URL}/admin/payout/get`, {headers: {"Authorization": `Bearer ${token}`}})

            if (response?.status !== 200) {
                /***
                Toast here
                 */
                return
            }
            var payouts = response.data.payouts.map(each => {
                each.SellerPayout.approve = (each.SellerPayout.status === "PENDING") ? <Button onClick={() => approvePayout(each)} appearance="primary" color="green">Approve</Button> : null
                each.SellerPayout.deny = (each.SellerPayout.status === "PENDING") ? <Button onClick={() => denyPayout(each)} appearance="primary" color="red">Deny</Button> : null
                return each
            })
            setPayouts(payouts)
        } catch {
           /**Toast here */ 
        }
    }

    useEffect(() => {
        fetchPayouts()
    }, [])
    
    return (
        <div className="mt-5">
            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
            />
            <PayoutTable columnData={payouts && payouts} columnDef={adminPayoutColDef}/>
        </div>
    )
}