import axios from "axios"
import { Button } from "rsuite"
import { useEffect, useState } from "react"
import { API_URL } from "utils/API"
import Dead from "./icons/dead.png"
import Pulse from "./icons/pulse.png"

export default function Worker() {
    const [status, setStatus] = useState(null)
    const getWorkerStatus = async () => {
        var response = await axios.get(`${API_URL}/worker/status`)
        if (response.status !== 200) {
            /* Toast here */
            return
        }
        setStatus(response.data.status)
    }

    const startWorker = async () => {
        var response = await axios.get(`${API_URL}/worker/start-worker`)
        getWorkerStatus()
    }

    const endWorker = async () => {
        var response = await axios.get(`${API_URL}/worker/end-worker`)
        getWorkerStatus()
    }

    useEffect(() => {
        getWorkerStatus()
    }, [])

    return (
        <div className="mt-5 space-y-5">
            <div className="flex space-x-5">
                <h5>Status</h5>
                {status === "running" ? <img src={Pulse} alt="" width={30}/> : <img src={Dead} alt="" width={30}/>}
            </div>
            <div className="space-x-5">
                <Button color="green" appearance="primary" onClick={() => startWorker()}>Start</Button>
                <Button color="red" appearance="primary" onClick={() => endWorker()}>Stop</Button>
            </div>
        </div>
    )
}