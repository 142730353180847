import React from "react";
import { Routes, Route, Link, Navigate, BrowserRouter, useNavigate} from "react-router-dom";
// Chakra imports
import { Flex, useColorModeValue } from "@chakra-ui/react";
import HearHour from "images/hearhour-cropped.png"
// Custom components
import { HorizonLogo } from "../../icons/Icons.js"; //components/icons/Icons.js

export function SidebarBrand() {
  //   Chakra color mode
  let logoColor = useColorModeValue("navy.700", "white");
  const navigate = useNavigate()
  return (
    <Flex align='center' direction='column'>
      <h3 onClick={() => navigate("/")} className="hover:cursor-pointer flex justify-center items-center">
      <img src={HearHour} alt="" width={100}/>
      <span>MMO SHOP</span>
      </h3>
      {/* <HorizonLogo h='26px' w='175px' my='32px' color={logoColor} /> */}
      {/* <HSeparator mb='20px' /> */}
    </Flex>
  );
}

export default SidebarBrand;
