import { useSelector } from "react-redux"
import { Button, InputNumber } from "rsuite"
import Select from "react-select"
import { useEffect, useState } from "react"
import axios from "axios"
import { API_URL } from "utils/API"
import { ToastContainer, toast } from 'react-toastify';
import { TOAST_OPTIONS } from "utils/toast";

export default function RequestPayout() {
    const user = useSelector(state => state.user)
    const [paymentOptions, setPaymentOptions] = useState([])
    const [payout, setPayout] = useState({
        amount: 0.0,
        id: null,
    })

    const fetchPaymentOptions = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.get(`${API_URL}/seller/payment-method/get`, {headers: {"Authorization": `Bearer ${token}`}})

        if (response.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }
        var paymentOptions = response.data.payment_methods.map(each => {
            return {value: each.id, label: `${each.payment_address} (${each.payment_type})`}
        })
        toast.success(response?.data?.message, TOAST_OPTIONS)
        setPaymentOptions(paymentOptions)
    }

    const requestPayout = async () => {
        var token = localStorage.getItem("hearhourshop")
        var response = await axios.post(`${API_URL}/seller/payout/request`, payout, {headers: {"Authorization": `Bearer ${token}`}})
        
        if (response.status !== 200) {
            toast.error(response?.data?.message, TOAST_OPTIONS)
            return
        }
        
        toast.success(response?.data?.message, TOAST_OPTIONS)
        setPayout({
            ...payout, amount: 0.0
        })
    }

    useEffect(() => {
        fetchPaymentOptions()
    }, [])

    return (
        <div className="mt-5 space-y-4">
            <h5><strong>Balance:</strong> $ {user?.metadata?.balance ? user.metadata.balance : 0}</h5>
            <p>Amount: Minimum $50<InputNumber prefix="$" onChange={(e) => setPayout({...payout, amount: e})} value={payout.amount} min={50.0}/></p>
            <p>Payment Option: <Select options={paymentOptions} onChange={(e) => setPayout({...payout, id: e.value})}/></p>
            <div className="ms-3">
                <Button onClick={() => requestPayout()} appearance="primary" color="blue">Request Payout</Button>
            </div>

            <ToastContainer
                    position="top-right"
                    autoClose={3500}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
            />
        </div>
    )
}