import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    } from '@chakra-ui/react'
import Logo from "../../images/logo.png"
import Gmail from "../../images/gmail.png"
import HearHour from "../../images/hearhour.png"
import payway from "../../images/Logo_payway.png"
import ABA_QR from "../../images/ABA_QR.png"
import { Button, Modal, Placeholder } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { Routes, Route, Link, Navigate, BrowserRouter, useNavigate, redirect} from "react-router-dom";
import { useEffect, useRef, useState } from 'react';
import "./Dashboard.css"


import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbSeparator,
    } from '@chakra-ui/react'
import TopUp from '../Dashboard/TopUpv2.jsx'
import Profile from '../Dashboard/Profile.jsx'
import TransactionHistory from '../Dashboard/TransactionHistory.jsx'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from "@auth0/auth0-react";

export default function Dashboard() {
    const user = useSelector(state => state.user)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const ws = useRef(null)
    const [current, setCurrent] = useState({
        profile: false,
        topUp: false,
        transactionHistory: true,
    })
    const { logout } = useAuth0();

    const [open, setOpen] = useState(false);
    const [backdrop, setBackdrop] = useState('static');
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    function signout() {

        // setUser({loggedIn: false})
        localStorage.removeItem("hearhourshop")
        localStorage.removeItem("redirector")
        logout({ logoutParams: { returnTo: window.location.origin } })
    }

    function changeCurrent(keyToSet) {
        var data = current
        for (const [key, value] of Object.entries(current)) {
            if (key === keyToSet) {
                data[key] = true
                localStorage.setItem("redirector", `${keyToSet}`)
            }
            else {data[key] = false}
        }
        setCurrent({...current, data})
    }

    useEffect(() => {
        var currentPage = localStorage.getItem("redirector")
        if (!(currentPage === null)) {
            changeCurrent(currentPage)
        }
    }, [])
    

    return (
        <div className='items-start header-bg main-background default-font' style={{position: 'relative', height: "100vh", width: "100vw", color:'black'}}>
            <div className="cursor-pointer w-40 md:w-96" onClick={() => navigate("/")}>
                <img src={HearHour} alt="" width={250} />
            </div>

            <div className="col-span-3">
            {/* user-dashboard */}
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center"}} className=''>
                
            <div className='1 rounded-xl text-black mt-15 md:m-10 background-svg' style={{width: "100vw", maxWidth:'1080px'}} >
            <div className='flex menu'>
                <Breadcrumb className='text-sm md:text-base lg:text-lg active:text-black' separator={"|"}>
                    <BreadcrumbItem>
                        <BreadcrumbLink onClick={() => navigate('/')}>Home </BreadcrumbLink>
                    </BreadcrumbItem>
                    
                    <BreadcrumbItem onClick={() => changeCurrent("profile")}>
                        <BreadcrumbLink isCurrentPage={current.profile} className={current.profile ? 'active' : ''}>Profile</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem onClick={() => changeCurrent("topUp")}>
                        <BreadcrumbLink isCurrentPage={current.topUp} className={current.topUp ? 'active' : ''}>Top Up</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem onClick={() => changeCurrent("transactionHistory")}>
                        <BreadcrumbLink isCurrentPage={current.transactionHistory} className={current.transactionHistory ? 'active' : ''}>Transaction History</BreadcrumbLink>
                    </BreadcrumbItem>

                    <BreadcrumbItem onClick={() => signout()}>
                        <BreadcrumbLink>Sign Out</BreadcrumbLink>
                    </BreadcrumbItem>
                </Breadcrumb>
            
                </div>
                    {current.profile && <Profile/>}
                    {current.topUp && <TopUp/>}
                    {current.transactionHistory && <TransactionHistory/>}
                </div>
            </div> 
        </div>
        </div>

        

        
    )
}