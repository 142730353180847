


export const columnsDataComplex = [
  {
    Header: "#",
    accessor: "#",
  },
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "USERNAME",
    accessor: "username",
  },
  {
    Header: "BALANCE",
    accessor: "balance",
  },
  {
    Header: "EMAIL",
    accessor: "email",
  },
  {
    Header: "admin",
    accessor: "admin",
  },
  ,
  {
    Header: "EIDT",
    accessor: "eidt",
  },
];
