import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
  } from '@chakra-ui/react'
import { useEffect, useState } from 'react';
import { Button, Modal } from 'rsuite';
import { get, post, API_URL } from '../../utils/API';
import { getLocalToken } from 'utils/token';
import { Input } from 'rsuite';

const one = {date: (new Date(1301090400 * 1000)).toLocaleDateString(), qty: 10, total: 10 * 0.0012, status: "COMPLETED"}
var data = []
for (let i = 0; i <= 10; i++) {
    data.push(one)
}  

export default function TransactionHistory() {
  const [transactions, setTransactions] = useState([])
  const [transactionDetails, setTransactionDetais] = useState([])
  const [current, setCurrent] = useState(null)
  const [open, setOpen] = useState(false);
  const [backdrop, setBackdrop] = useState('static');
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
      setCurrent(null)
      setOpen(false);
  }

  const getTransactions = async () => {
    var token = localStorage.getItem("hearhourshop")
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history?token=${token}`)
    var response = await get(`${API_URL}/profile/transaction-history`, token)
    setTransactions(response.transactions)
  }

  const viewTransactionDetails = async (transaction) => {
    var token = localStorage.getItem("hearhourshop")
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history/view/${transactionId}?token=${token}`)
    var response = await get(`${API_URL}/profile/transaction-history/view/${transaction?.id}`, token)
    processTransactionDetails(response.transaction_details)
    handleOpen()
    setCurrent(transaction)
  }

  const processTransactionDetails = (transactionDetails) => {
    var details = ""
    transactionDetails?.map(each => {
      details += each.detail + "\n"
    })
    details = details.substring(0, details.length - 1)
    // console.log(details)
    setTransactionDetais(details)
  }

  const exportTransactionDetails = async (transactionId) => {
    var token = localStorage.getItem("hearhourshop")
    // var response = await get(`https://mmoshop.hearhour.repl.co/profile/transaction-history/export/${transactionId}?token=${token}`)
    // await fetch(`https://mmoshop.hearhour.repl.co/profile/transaction-history/export/${transactionId}?token=${token}`)
    // var response = await get(`http://localhost:8000/profile/transaction-history/export/${transactionId}?token=${token}`)
    await fetch(`${API_URL}/profile/transaction-history/export/${transactionId}`, {headers: {"Authorization": "Bearer " + getLocalToken() }})
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${transactionId}.txt`; // Specify the filename
      a.click();
      window.URL.revokeObjectURL(url);
    })
    .catch(error => {
      // Handle error
      console.error('Error:', error);
    });
  }

  useEffect(() => {
    getTransactions()
    // var interval = setInterval(() => getTransactions(), 10000)
    // return () => {
    //   clearInterval(interval)
    // }
  }, [])

    return (
          <div className='body-user' >
          <div className='body-content pb-10'>
          <div className="grid items-center justify-center">
          <div className="col-span-4 lg:col-span-2 tablemain">
                
        <TableContainer className='tablesr scroll5 table-con' style={{ width: "100%", height: '480px', overflowX:'hidden', overflowY: 'auto',paddingTop:'0px' }}>
  <Table variant='simple'>
    <Thead className='header-table'>
      <Tr>
        <Th className='tha'><p className='table-p'>Date</p> </Th>
        <Th className='hidden md:table-cell tha'> <p className='table-p'>Product</p> </Th>
        <Th  className='hidden md:table-cell tha'><p className='table-p'>Transaction ID</p></Th>
        <Th  className='hidden md:table-cell tha'><p className='table-p'>Amount</p></Th>
        <Th  className='md:table-cell tha'></Th>
        <Th  className='md:table-cell tha'></Th>
      </Tr>
    </Thead>
    <Tbody>
      {transactions?.map((each) => {
    const date = new Date(each?.date);
    const formattedDate = date.toLocaleString();

    return (
        <Tr  key={each.title}>

          <Td className='hidden md:table-cell ui-td'>{formattedDate}</Td>
          <Td className='ui-td'>{each?.product_name}</Td>
          <Td className='hidden md:table-cell ui-td'>{each?.id}</Td>
          <Td className='hidden md:table-cell ui-td'>${parseFloat(each?.total).toFixed(3)}</Td>
          <Td className='md:table-cell ui-td'><Button className='transastion-button' onClick={() => viewTransactionDetails(each)}>View</Button></Td>
          <Td className='hidden md:table-cell ui-td'><Button className='transastion-button' onClick={() => exportTransactionDetails(each?.id)}>Export</Button></Td>
        </Tr>
      )})}
    </Tbody>
  </Table>
</TableContainer>
  {open && current && 
    <Modal backdrop={backdrop} keyboard={false} open={open} onClose={handleClose} size="md" >
        <Modal.Header>
          <h5>Transaction Details</h5>
        </Modal.Header>

        <Modal.Body>
          <Input 
          as="textarea" 
          rows={30} 
          placeholder="Accounts" 
          value={transactionDetails && transactionDetails} 
          onChange={(e) => setTransactionDetais(e)}/>
        </Modal.Body>
        <Modal.Footer>
        <div className='grid grid-cols-2 items-center justify-center'>
          <Button className="button-buy" onClick={() => exportTransactionDetails(current?.id)} style={{color: "black"}}>
            Download .txt File
          </Button>
          <Button className="button-buy" onClick={handleClose}>
            Cancel
          </Button>
          </div>
        </Modal.Footer>
      </Modal>
  }

    </div>
    </div>
    </div>
    </div>
    )
}