
import EyeIcon from '@rsuite/icons/legacy/Eye';
import EyeSlashIcon from '@rsuite/icons/legacy/EyeSlash';
import { Wrap, WrapItem, Avatar } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { get, API_URL } from '../../utils/API';
import { Button, Modal, Placeholder, Input } from 'rsuite';
import { Heading, Highlight, CircularProgress, Textarea, Box } from '@chakra-ui/react';

export default function Profile({user, setUser}) {
    const [visible, setVisible] = useState(false);
    const [isLoading, setIsLoading] = useState(false); //
    const [inputText, setInputText] = useState('');
    const [showText, setShowText] = useState('');
    const [uidislive, setuidislive] = useState('');
    const [uidisdie, setuidisdie] = useState('');
    const [rowlive, setRowlive] = useState('');
    const [rowdie, setRowdie] = useState('');
    const [copiedText, setCopiedText] = useState('');
    const [copiedUIDdie, setCopiedUIDdie] = useState('');
    const handleChange = () => {
        setVisible(!visible);
    };


    const handleStartClick = async () => {
      // const responsess = await axios.get('http://localhost:5000/api/data')
      // console.log(responsess.message)



      try {
        setuidislive('');
        setuidisdie('');
        setRowdie('');
        setIsLoading(true); // Show the loading indicator
    
        const userInputLines = inputText.trim().split('\n'); // Split the input into lines
        const numThreads = 30;
        let currentIndex = 0;
        let rowliveCount = 0;
        let rowdieCount = 0;
    
        while (currentIndex < userInputLines.length) {
          const chunk = userInputLines.slice(currentIndex, currentIndex + numThreads);
          currentIndex += numThreads;
    
          const chunkPromises = chunk.map(async (userInput) => {
            if (!userInput.trim()) {
              return;
            }
    
            try {
              const response = await get(`https://graph2.facebook.com/v3.3/${userInput}/picture?redirect=0`);
              if (response.data.height === 50) {
                setuidislive((prevState) => prevState + userInput + '\n');
                rowliveCount++;
                setRowlive(rowliveCount.toString());
              } else {
                setuidisdie((prevState) => prevState + userInput + '\n');
                rowdieCount++;
                setRowdie(rowdieCount.toString());
              }
            } catch (error) {
            }
          });
    
          await Promise.all(chunkPromises);
        }
    
        setuidislive((prevState) => prevState.slice(0, -1));
        setuidisdie((prevState) => prevState.slice(0, -1));
    
        setCopiedText(uidislive.trim());
        setCopiedText(uidisdie.trim());
    
        setRowlive(rowliveCount.toString());
      } catch (error) {
      } finally {
        setIsLoading(false); // Hide the loading indicator
      }
    };
    



    return (
        <div className='body-user'>

            <div className='body-tools pb-10'>
                <Heading lineHeight='tall'>
                    <Box fontSize='18px'> 
                    <Highlight
                        query='LIVE'
                        styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.400'}}
                    >
                        Check LIVE UID Accounts 
                    </Highlight>  {rowlive}
                    {isLoading && <CircularProgress isIndeterminate color='green.300' size='30px'/>}
                    </Box> 
                    
                </Heading>
                
                <div className="grid grid-cols-1 items-center justify-center">
                <div className="col-span-1 lg:col-span-1" style={{ position: 'relative', margin:'20px 15px' }}>
                    <div className="textarea" style={{backgroundColor:'#ffffff4f'}}>
                    <Textarea className="user-input scroll scroll4" placeholder="Enter List UID" value={inputText} onChange={(e) => setInputText(e.target.value)}/>
                    </div>
                    <div className='sssssss'>
                        <Button className='button-start' onClick={handleStartClick}>Ckeck Live UID</Button>
                    </div>
                    
                </div>
                </div>
            </div>




            <div className='grid grid-cols-2'>
                <div className='col-span-5 md:col-span-1'>


                <div className="col-span-5 lg:col-span-1" style={{ position: 'relative'}}>
                    <div className="textarea" style={{backgroundColor:'#ffffff3d', margin:'10px 15px'}}>
                        <Textarea className="user-input scroll4" placeholder="UID LIVE" value={uidislive} onChange={(e) => setuidislive(e.target.value)}/>
                    </div>
                    <Button className='button-copy' onClick={() => navigator.clipboard.writeText(uidislive)}>
                        {rowlive} COPY LIVE
                    </Button>
                </div>
                </div>
                
                <div className="col-span-5 md:col-span-1">
                <div className="col-span-1 lg:col-span-1" style={{ position: 'relative'}}>
                <div className="textarea" style={{backgroundColor:'#ffffff3d', margin:'10px 15px'}}>
                    <Textarea className="user-input scroll4" placeholder="UID DIE" value={uidisdie} onChange={(e) => setuidisdie(e.target.value)}/>
                </div>
                <Button className='button-copy' onClick={() => navigator.clipboard.writeText(uidisdie)}>
                        {rowdie} COPY DIE
                    </Button>
                </div>

                </div>
            </div>
            
        </div>
    )
}