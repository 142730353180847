import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  } from '@chakra-ui/react'
import {get} from "../../utils/API.js"
import { useState, useEffect, useRef } from 'react'
import { API_URL } from '../../utils/API.js'
import { Heading, Highlight, CircularProgress, Textarea, Box } from '@chakra-ui/react';
import { Button, Modal, Placeholder, Input } from 'rsuite';
export default function TopUp({user}) {
  const [invoices, setInvoices] = useState([])
  const ws = useRef(null)

  const [isLoading, setIsLoading] = useState(false); //
  const [inputText, setInputText] = useState('');
  const [showText, setShowText] = useState('');
  const [totalCount, setTotalCount] = useState('');



  const handleStartClick = async () => {
    try {
      setIsLoading(true); // Show the loading indicator
      const userInputLines = inputText.trim().split('\n').slice(0, 10); // Split the input into lines and limit it to 10 lines
      let count = 0; // Variable to store the count of rows
      
      for (let i = 0; i < userInputLines.length; i++) {
        const userInput = userInputLines[i].trim();
        
        if (!userInput) {
          console.error('Error: User input is empty');
          continue;
        }
    
        const data = await get(`${API_URL}/profile/scangroups?user=${userInput}`);
        const response = JSON.parse(data);
    
        if (response.code === 200 && Array.isArray(response.Groups)) {
          const formattedData = response.Groups.map(group => {
            const {
              id,
              name,
              viewer_post_status,
              visibility,
              group_member_profiles: { count }
            } = group;
            return `${id}|${name}|${visibility}|${count}`;
          }).join('\n');
    
          setShowText(prevText => prevText + formattedData + '\n');
          count += response.Groups.length; // Update the count
        } else {
          console.error('Error fetching data for input:', userInput);
        }
      }
      
      setTotalCount(count.toString() + ' Groups'); // Set the total count 
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false); // Hide the loading indicator
      
      // setInputText(''); // Clear the input text
    }
  };
  


  return (
      <div className='body-user'>

          <div className='body-tools pb-10'>
              <Heading lineHeight='tall'>
                  <Box fontSize='18px'> 
                  <Highlight
                      query='UID'
                      styles={{ px: '2', py: '1', rounded: 'full', bg: 'green.400'}}
                  >
                      Get Groups by UID Accounts
                  </Highlight> {totalCount}
                  {isLoading && <CircularProgress isIndeterminate color='green.300' size='30px'/>}
                  </Box>
                  
              </Heading>
              
              <div className="grid grid-cols-1 items-center justify-center">
              <div className="col-span-1 lg:col-span-1" style={{ position: 'relative',margin:'20px 0' }}>
                  <div className="textarea" style={{backgroundColor:'#ffffff4f'}}>
                  <Textarea className="user-input scroll scroll4" placeholder="Enter Your UID" value={inputText} onChange={(e) => setInputText(e.target.value)}/>
                  </div>
                  <div className='sssssss'>
                      <Button className='button-start' onClick={handleStartClick}>START</Button>
                      
                  </div>
                  
              </div>

              {showText && (
              <div className="col-span-1 lg:col-span-1" style={{ position: "relative" }}>
                  <div className="textarea-show" style={{ backgroundColor: "#ffffff4a" }}>
                  <Textarea
                      className="user-show scroll scroll4"
                      placeholder="output"
                      value={showText}
                      onChange={(e) => setShowText(e.target.value)}
                      style={{ minHeight: "300px" }}
                  />
                  </div>
              </div>
              )}
              </div>
          </div>
      </div>

  )
}