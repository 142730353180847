import axios from "axios"
import { useEffect, useState } from "react"
import { API_URL } from "utils/API"
import PayoutTable from "./PayoutTable"

const payoutColDef = [
    {
      header: "ID",
      accessor: "SellerPayout.id",
    },
    {
      header: "USERNAME",
      accessor: "SellerPayout.username",
    },
    {
      header: "SUBMITTED DATE",
      accessor: "SellerPayout.submitted_date",
    },
    {
      header: "RESPONDED DATE",
      accessor: "SellerPayout.responded_date",
    },
    {
      header: "AMOUNT",
      accessor: "SellerPayout.amount",
    },
    {
      header: "PAYMENT TYPE",
      accessor: "SellerPaymentMethod.payment_type",
    },
    {
      header: "PAYMENT ADDRESS",
      accessor: "SellerPaymentMethod.payment_address",
    },
    {
        header: "STATUS",
        accessor: "SellerPayout.status"
    }
  ];

export default function PayoutHistory() {
    const [payouts, setPayouts] = useState([])

    const fetchPayouts = async () => {
        var token = localStorage.getItem("hearhourshop");
        try {
            var response = await axios.get(`${API_URL}/seller/payout/get`, { headers: { "Authorization": `Bearer ${token}` } })
            if (response?.status !== 200) {
                /**Toast here */
                return;
            }
    
            setPayouts(response.data.payouts);
        } catch (error) {
            if (error.response && error.response.status === 422) {
            } else {
                console.error("An error occurred:", error);
            }
        }
    };

    useEffect(() => {
        fetchPayouts()
    }, [])

    return (
        <div className="mt-5">
            <PayoutTable columnData={payouts && payouts} columnDef={payoutColDef}/>
        </div>
    )
}